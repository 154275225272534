import { addDoc, collection, deleteDoc, doc, onSnapshot, orderBy, query, serverTimestamp, updateDoc } from "firebase/firestore";
import { Checkbox, IconButton, Tooltip, Zoom } from "@mui/material";
import AddIncreasesOrDecreases from "../AddIncreasesOrDecreases";
import { db } from "../../../assets/context/firebase-config";
import { useThis } from "../../../assets/context/Context";
import { Edit, DeleteForever } from "@mui/icons-material";
import GenericGridType1 from "../grids/GenericGridType1";
import { formatDate } from "../../../assets/js/Commons";
import ExposureIcon from "@mui/icons-material/Exposure";
import PostAddIcon from "@mui/icons-material/PostAdd";
import getConstant from "../../../assets/js/Constant";
import endpoints from "../../../assets/js/Endpoints";
import LiquorIcon from "@mui/icons-material/Liquor";
import { useEffect, useRef, useState } from "react";
import HeaderProducts from "../HeaderProducts";
import AddProdAndServ from "../AddProdAndServ";
import DeleteAnything from "../DeleteAnything";
import Modal from "../../containers/Modal";
import LoadingBar from "../LoadingBar";
import Message from "../Message";
import { v4 } from "uuid";

/**
 * @name ProductsAndServices
 * @description Método que retorna la vista del módulo de productos y servicios
 * @returns View Muestra el módulo de productos y servicios
 * @version 1.0
 */
const ProductsAndServices = () => {
  const css = styles();
  const constant = getConstant();
  const { lang, branch, checked, setChecked, idsRows, setIdsRows } = useThis();

  const nameFocus = useRef(null);
  const amountFocus = useRef(null);
  const amountInventoryFocus = useRef(null);

  const [row, setRow] = useState({});
  const [data, setData] = useState({});
  const [rows, setRows] = useState([]);
  const [snack, setSnack] = useState([]); // [Índice 0 = código del mensaje, Índice 1 = tipo de mensaje]
  const [edit, setEdit] = useState(false);
  const [idsDoc, setIdsDoc] = useState([]);
  const [auxRows, setAuxRows] = useState([]);
  const [subRows, setSubRows] = useState([]);
  const [idToEdit, setIdToEdit] = useState("");
  const [loading, setLoading] = useState(false); // Para el modal
  const [deleteCode, setDeleteCode] = useState(0); // 0 = Modal para eliminar fila, 1 = Modal para eliminar filas
  const [dataAction, setDataAction] = useState({});
  const [snackLocal, setSnackLocal] = useState([]); // [Índice 0 = código del mensaje, Índice 1 = tipo de mensaje]
  const [loadingBar, setLoadingBar] = useState(false); // Para la tabla
  const [deleteMessage, setDeleteMessage] = useState("");
  const [openModalToDelete, setOpenModalToDelete] = useState(false);
  const [openAddProdAndServ, setOpenAddProdAndServ] = useState(false);
  const [openIncreasesOrDecreases, setOpenIncreasesOrDecreases] = useState(false);

  /**
   * @name filterProdAndServ
   * @description Filtra los productos de la tabla por nombre
   * @param {*} e
   * @version 1.0
   */
  const filterProdAndServ = (e) => {
    const products = auxRows.reduce((acc, item) => {
      if (item[0].toLowerCase().includes(e.target.value.toLowerCase())) acc.push(item);
      return acc;
    }, []);
    setRows(products);
  };

  /**
   * @name showCreateProdAndServ
   * @description Método para mostrar el modal de crear o editar un producto
   * @version 1.0
   */
  const showCreateProdAndServ = () => {
    setEdit(false);
    /** Inicializa los datos */
    setData(resetData());
    /** Muestra el modal para crear o ediar productos */
    setOpenAddProdAndServ(true);
  };

  /**
   * @name editUser
   * @description Método que maneja el evento del botón editar de cada fila
   * @param {Object} params
   * @version 1.0
   */
  const actionRowEdit = (params) => {
    setEdit(true);
    setData(params);
    setIdToEdit(params.id);
    setOpenAddProdAndServ(true);
  };

  /**
   * @name resetData
   * @description Método que inicializa los datos
   * @version 1.0
   */
  const resetData = () => {
    return { name: "", amount: "", history: {} };
  };

  /**
   * @name resetDataAction
   * @description Método que inicializa los datos
   * @version 1.0
   */
  const resetDataAction = () => {
    return { actionCode: "action1", action: lang.increases, amount: "", reason: lang.stock, reasonCode: "reason1", obs: "" };
  };

  /**
   * @name onHandleCheck
   * @description Método que maneja el comportamiento de los checkbox de cada fila
   * @version 1.0
   */
  const onCheckbox = () => setChecked(!checked);

  /**
   * @name addProdAndServ
   * @description Método para editar o agregar un producto
   * @returns N/A
   * @version 1.0
   */
  const addOrEditProdAndServ = async () => {
    if (loading) return;

    // Se valida que no sea vacío el nombre de la categoría
    if (!data.name) {
      nameFocus.current.focus();
      return setSnack([83, constant.error]);
    }
    // Se valida que no sea vacío el nombre de la categoría
    if (!data.amount) {
      amountFocus.current.focus();
      return setSnack([84, constant.error]);
    }

    setLoading(true);

    try {
      if (edit) {
        // Se actualiza el producto o servicio
        await updateDoc(doc(db, endpoints.product(branch.administrator, branch.branchId, idToEdit)), { name: data.name });
      } else {
        // Se agrega el documento del producto
        data.history[v4()] = { action: lang.increase, amount: data.amount, date: serverTimestamp(), reason: lang.stock };
        await addDoc(collection(db, endpoints.products(branch.administrator, branch.branchId)), data);
      }
      setIdsRows({});
      setOpenAddProdAndServ(false);
      setSnackLocal([edit ? 85 : 81, constant.success]);
    } catch (error) {
      setSnack([edit ? 86 : 82, constant.error]);
    }

    setLoading(false);
  };

  /**
   * @name deleteRow
   * @description Método que elimina un producto o servicio
   * @version 1.0
   */
  const deleteRow = async () => {
    if (loading) return;
    setLoading(true);

    try {
      await deleteDoc(doc(db, endpoints.product(branch.administrator, branch.branchId, row.id)));
      setSnackLocal([87, constant.success]);
      setOpenModalToDelete(false);
    } catch (error) {
      setSnack([88, constant.error]);
    }
    setLoading(false);
  };

  /**
   * @name deleteRows
   * @description Método para eliminar varias líneas
   * @version 1.0
   */
  const deleteRows = async () => {
    if (loading) return;
    setLoading(true);
    let hasErrors = false;
    for (let x = 0; x < Object.keys(idsRows).length; x++) {
      try {
        await deleteDoc(doc(db, endpoints.product(branch.administrator, branch.branchId, Object.keys(idsRows)[x])));
      } catch (error) {
        hasErrors = true;
      }
    }
    setLoading(false);
    if (hasErrors) return setSnack([79, constant.error]);
    setIdsRows({});
    setOpenModalToDelete(false);
    setSnackLocal([89, constant.success]);
  };

  /**
   * @name increasesOrDecreases
   * @description Método que muetra el modal para modificar el inventario
   * @param {Object} dataRow
   * @version 1.0
   */
  const showIncreasesOrDecreases = (dataRow) => {
    /** Muestra el modal para modificar el inventario */
    setOpenIncreasesOrDecreases(true);
    /** Inicializa los datos */
    setDataAction({ ...resetDataAction(), item: dataRow.name, id: dataRow.id, history: dataRow.history, oldAmount: dataRow.amount });
  };

  /**
   * @name showDeleteProdsAndServs
   * @description Método que muestra el modal para eliminar productos o servicios
   * @version 1.0
   */
  const showDeleteProdsAndServs = () => {
    setDeleteCode(1);
    setOpenModalToDelete(true);
    setDeleteMessage(lang.deleteProductsMessage.replace("[ITEMS]", Object.keys(idsRows).length));
  };

  /**
   * @name showDeleteProdAndServ
   * @description Método que muestra el modal para eliminar solo un producto o servicio
   * @version 1.0
   */
  const showDeleteProdAndServ = (dataRow) => {
    setRow(dataRow);
    setDeleteCode(0);
    setOpenModalToDelete(true);
    setDeleteMessage(lang.deleteProdcutMessage.replace("[ITEM]", dataRow.name));
  };

  /**
   * @name addIncreasesOrDecreases
   * @description Método que muestra el modal para gestionar el inventario de productos o servicios
   * @version 1.0
   */
  const addIncreasesOrDecreases = async () => {
    if (loading) return;

    // Se valida que no sea vacío el nombre de la categoría
    if (!dataAction.amount) {
      amountInventoryFocus.current.focus();
      return setSnack([84, constant.error]);
    }

    setLoading(true);

    try {
      // Se agrega el documento del producto
      const obs = dataAction.obs ? " - " + dataAction.obs : "";
      dataAction.history[v4()] = { action: dataAction.action, amount: dataAction.amount, date: serverTimestamp(), reason: dataAction.reason + obs };
      // Se actualiza el historial y la cantidad
      const amount =
        dataAction.actionCode === "action1" ? dataAction.oldAmount * 1 + dataAction.amount * 1 : dataAction.oldAmount * 1 - dataAction.amount * 1;
      const history = { history: dataAction.history, amount: amount.toString() };
      await updateDoc(doc(db, endpoints.product(branch.administrator, branch.branchId, dataAction.id)), history);
      setOpenIncreasesOrDecreases(false);
      setSnackLocal([81, constant.success]);
    } catch (error) {
      setSnack([82, constant.error]);
    }
    setLoading(false);
  };

  /**
   * @name createRowButtons
   * @description Método que retorna los botones de la fila
   * @version 1.0
   */
  const createRowButtons = (dataRow) => (
    <div style={css.butons}>
      <Tooltip TransitionComponent={Zoom} title={lang.increasesOrDecreases}>
        <IconButton key={v4()} onMouseUp={() => showIncreasesOrDecreases(dataRow)} sx={{ color: constant.checkColor }}>
          <ExposureIcon />
        </IconButton>
      </Tooltip>
      <Tooltip TransitionComponent={Zoom} title={lang.editRow}>
        <IconButton key={v4()} onMouseUp={() => actionRowEdit(dataRow)} sx={{ color: constant.editColor }}>
          <Edit />
        </IconButton>
      </Tooltip>
      <Tooltip TransitionComponent={Zoom} title={lang.deleteRow}>
        <IconButton key={v4()} onMouseUp={() => showDeleteProdAndServ(dataRow)} sx={{ color: constant.deleteColor }}>
          <DeleteForever />
        </IconButton>
      </Tooltip>
    </div>
  );

  /**
   * @description Objeto para la creación del modal agregar o editar productos
   */
  const parametersToAddProdAndServ = {
    btnText: edit ? lang.edit : lang.add,
    icon: edit ? <Edit /> : <PostAddIcon />,
    title: edit ? lang.editItem : lang.newItem,
    content: <AddProdAndServ data={data} setData={setData} nameFocus={nameFocus} amountFocus={amountFocus} edit={edit} />,
  };

  /**
   * @description Objeto para la creación del modal eliminar producto o servicio
   */
  const paramsToDeleteProdAndServ = {
    btnText: lang.delete,
    title: lang.deleteItems,
    icon: <DeleteForever />,
    content: <DeleteAnything message={deleteMessage} />,
  };

  /**
   * @description Objeto para la creación del modal para el manejo del inventario
   */
  const paramsIncreasesOrDecreases = {
    btnText: lang.add,
    icon: <ExposureIcon />,
    title: lang.manageInventory,
    content: <AddIncreasesOrDecreases dataAction={dataAction} setDataAction={setDataAction} amountInventoryFocus={amountInventoryFocus} />,
  };

  // Estructura del grid de productos
  const subColumns = [lang.date, lang.action, lang.reason, lang.amount];
  const columns = [<Checkbox checked={checked} onClick={onCheckbox} size="small" />, <LiquorIcon />, lang.productOrServ, lang.amount, lang.actions];

  /**
   * @name sortHistory
   * @description Método encargado de ordenar el historial por fecha
   * @param {Array} history
   * @returns {Array}
   * @version 1.0
   */
  const sortHistory = (history) =>
    history.sort((a, b) => {
      const dateA = new Date(a[0].replace(lang.at, " "));
      const dateB = new Date(b[0].replace(lang.at, " "));
      return dateB - dateA;
    });

  /** Efecto que mantiene actualizada la tabla de productos */
  useEffect(() => {
    setLoadingBar(true);
    const collectionProducts = collection(db, endpoints.products(branch.administrator, branch.branchId));
    const queryProducts = query(collectionProducts, orderBy("name", "asc"));
    // Escucha los cambios en los datos de productos
    const unsubscribe = onSnapshot(queryProducts, (snapshot) => {
      // Almacena las filas de cada producto
      const products = snapshot.docs.reduce(
        (acc, doc) => {
          const obj = doc.data().history;
          const multiRows = Object.keys(obj).reduce((acc2, key) => {
            acc2.push([formatDate(obj[key].date, lang), obj[key].action, obj[key].reason, obj[key].amount]);
            return acc2;
          }, []);
          acc.ids.push(doc.id);
          acc.subRows.push(sortHistory(multiRows));
          acc.rows.push([doc.data().name, doc.data().amount, createRowButtons({ ...doc.data(), id: doc.id })]);
          return acc;
        },
        { rows: [], subRows: [], ids: [] }
      );
      setLoadingBar(false);
      setRows(products.rows);
      setIdsDoc(products.ids);
      setAuxRows(products.rows);
      setSubRows(products.subRows);
    });
    return () => unsubscribe();
  }, []);

  return (
    <div style={css.box}>
      <HeaderProducts
        icons={1}
        toolTip={lang.addProdAndServ}
        title={lang.manageProdAndServ}
        actionSearch={filterProdAndServ}
        selectedRows={Object.keys(idsRows)}
        actionButton={showCreateProdAndServ}
        actionButtonDelete={showDeleteProdsAndServs}
      />
      <LoadingBar visible={loadingBar} />
      <GenericGridType1
        rows={rows}
        idsDoc={idsDoc}
        subRows={subRows}
        columns={columns}
        showCheckbox={true}
        multipleSubRows={true}
        subColumns={subColumns}
        subTitle={lang.historic}
      />
      {/** Modal para agregar o editar un producto */}
      <Modal
        snack={snack}
        loading={loading}
        setSnack={setSnack}
        open={openAddProdAndServ}
        setOpen={setOpenAddProdAndServ}
        clickBtnOk={addOrEditProdAndServ}
        icon={parametersToAddProdAndServ.icon}
        title={parametersToAddProdAndServ.title}
        btnText={parametersToAddProdAndServ.btnText}
        content={parametersToAddProdAndServ.content}
      />
      {/** Modal para eliminar una categoría */}
      <Modal
        snack={snack}
        loading={loading}
        setSnack={setSnack}
        open={openModalToDelete}
        colorBar={constant.error}
        color={constant.deleteColor}
        setOpen={setOpenModalToDelete}
        icon={paramsToDeleteProdAndServ.icon}
        title={paramsToDeleteProdAndServ.title}
        btnText={paramsToDeleteProdAndServ.btnText}
        content={paramsToDeleteProdAndServ.content}
        clickBtnOk={deleteCode ? deleteRows : deleteRow}
      />
      {/** Modal para incrementar o disminuir el inventario */}
      <Modal
        snack={snack}
        loading={loading}
        setSnack={setSnack}
        open={openIncreasesOrDecreases}
        clickBtnOk={addIncreasesOrDecreases}
        setOpen={setOpenIncreasesOrDecreases}
        icon={paramsIncreasesOrDecreases.icon}
        title={paramsIncreasesOrDecreases.title}
        btnText={paramsIncreasesOrDecreases.btnText}
        content={paramsIncreasesOrDecreases.content}
      />
      <Message snack={snackLocal}></Message>
    </div>
  );
};

/**
 * @name styles
 * @description Método encargado de devolver los estilos a los componentes
 * @returns Object
 * @version 1.0
 */
const styles = () => {
  return {
    box: { width: "100%" },
    butons: { display: "flex", justifyContent: "right" },
  };
};

export default ProductsAndServices;
