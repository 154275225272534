import { LinearProgress } from "@mui/material";

/**
 * @name LoadingBar
 * @description Método que devuelve un componente de tipo LoadingBar
 * @param {String} margin - 1px 1px 1px 1px
 * @param {Boolean} visible - Default false
 * @param {String} color
 * @returns View
 * @version 1.0
 */
const LoadingBar = ({ margin, visible = false, color = "success" }) => {
  const css = styles({ margin, visible });

  return <LinearProgress sx={css.loading} color={color} />;
};

/**
 * @name styles
 * @description Método encargado de devolver los estilos a los componentes
 * @returns Object
 * @version 1.0
 */
const styles = ({ margin, visible }) => {
  return {
    loading: { margin, visibility: visible ? "visible" : "hidden" },
  };
};

export default LoadingBar;
