import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";
import { useThis } from "../../assets/context/Context";
import { useEffect, useRef } from "react";
import InputText from "./InputText";
import Label from "./Label";

/**
 * @name StepVerticalRegister1
 * @description Método que devuelve un componente de tipo StepVerticalRegister1
 * @param {useState} uf - Variable que maneja el username focus del input
 * @param {useState} setUf
 * @returns View
 * @version 1.0
 */
const StepVerticalRegister1 = ({ uf, setUf }) => {
  const css = styles();
  const { lang, data, setData } = useThis();

  const userFocus = useRef(null);

  useEffect(() => {
    if (uf) {
      userFocus.current.focus();
      setUf(false);
    }
  }, [uf]);

  return (
    <div style={css.container}>
      <div style={css.fragment}>
        <div>
          <Label text={lang.createUser} fontSize="20px" fontWeight="500" />
          <Label text={lang.createUserText} fontSize="16px" />
          <InputText
            data={data}
            marginTop={3}
            size={"small"}
            type={"email"}
            width={"auto"}
            name="userName"
            setData={setData}
            referece={userFocus}
            value={data.userName}
            label={lang.userName}
            icon={<EmojiPeopleIcon />}
          />
        </div>
      </div>
    </div>
  );
};

/**
 * @name styles
 * @description Método encargado de devolver los estilos a los componentes
 * @returns Object
 */
const styles = () => {
  return {
    container: { width: "100%", position: "relative" },
    fragment: { display: "flex", justifyContent: "space-between", alignItems: "center", paddingTop: "10px" },
  };
};

export default StepVerticalRegister1;
