import { userRegistration } from "../../assets/js/firebaseMethods";
import StepVerticalRegister1 from "./StepVerticalRegister1";
import StepVerticalRegister2 from "./StepVerticalRegister2";
import StepVerticalRegister3 from "./StepVerticalRegister3";
import { useThis } from "../../assets/context/Context";
import StepContent from "@mui/material/StepContent";
import getConstant from "../../assets/js/Constant";
import StepLabel from "@mui/material/StepLabel";
import { useEffect, useState } from "react";
import Stepper from "@mui/material/Stepper";
import ArrowButton from "./ArrowButton";
import Step from "@mui/material/Step";
import Box from "@mui/material/Box";
import Message from "./Message";
import Label from "./Label";
import Login from "./Login";

/**
 * @name StepperVertical
 * @description Método que devuelve un componente de tipo StepperVertical
 * @param {useState} steps Array con los nombres de los pasos
 * @returns View
 * @version 1.0
 */
const StepperVertical = ({ steps }) => {
  const constant = getConstant();
  const css = styles(constant.primaryColor);
  const { lang, step, setStep, setData, setForm, data, signup, updateUser, sendEmailVerification, user, verified, sent, sending, error } = useThis();

  const [uf, setUf] = useState(false); // Estado del focus para el input userName
  const [ef, setEf] = useState(false); // Estado del focus para el input email
  const [pf, setPf] = useState(false); // Estado del focus para el input password
  const [snack, setSnack] = useState([]); // [Índice 0 = código del mensaje, Índice 1 = tipo de mensaje]
  const [loading, setLoading] = useState(false); // Barra de registrando correo en proceso del paso 2
  const [sendMail, setSendMail] = useState(false); // Activa el método de envío de correo del paso 3

  // Array con las vistas de los pasos
  const views = [
    <StepVerticalRegister1 uf={uf} setUf={setUf} />,
    <StepVerticalRegister2 ef={ef} setEf={setEf} pf={pf} setPf={setPf} loading={loading} />,
    <StepVerticalRegister3 sendMail={sendMail} setSendMail={setSendMail} />,
    <StepVerticalRegister3 sendMail={sendMail} setSendMail={setSendMail} />,
  ];

  /**
   * @name onClickToBack
   * @description Método que se devuelve a la pantalla de login
   * @returns N/A
   */
  const onClickToLogin = () => setForm(<Login />);

  /**
   * @name onClickToReload
   * @description Método que recarga la página para inciar el login
   * @returns N/A
   */
  const onClickToReload = () => {
    if (sent) window.location.reload();
  };

  /**
   * @name onClickToSend
   * @description Método que llama el método de enviar validación
   * @returns N/A
   */
  const onClickToSend = () => {
    if (sending) return;
    setSendMail(true);
  };

  /**
   * @name handleNext
   * @description Método que maneja el evento de la flecha siguiente
   * @returns N/A
   */
  const handleNext = async () => {
    if (step === 0 && !data.userName) {
      setUf(true);
      return setSnack([4, constant.error]);
    } else if (step === 1) {
      const success = await register();
      if (!success) return;
    } else if (step === 2) return;

    setStep((prev) => prev + 1);
  };

  /**
   * @name handleBack
   * @description Método que maneja el evento de la flecha atrás
   * @returns N/A
   */
  const handleBack = () => {
    if (step === 0) {
      cleanData();
      setForm(<Login />);
      return;
    }
    setStep((prev) => prev - 1);
  };

  /**
   * @name register
   * @description Método que valida el resgistro y avanza un paso del Stepper
   * @returns N/A
   */
  const register = async () => {
    if (loading) return false;

    if (!data.email) {
      setEf(true);
      setSnack([1, constant.error]);
      return false;
    }
    if (!data.password) {
      setPf(true);
      setSnack([2, constant.error]);
      return false;
    }
    if (data.password.length < constant.passwordLength) {
      setPf(true);
      setSnack([5, constant.error]);
      return false;
    }

    setLoading(true);
    const request = {
      userName: data.userName,
      email: data.email,
      password: data.password,
      signup,
      updateUser,
      sendEmailVerification,
    };
    const result = await userRegistration(request);
    setLoading(false);

    if (result.error) {
      setSnack([result.message, constant.error]);
      return false;
    }

    return true;
  };

  /**
   * @name addSteps
   * @description Método que carga los pasos al Stepper
   */
  const addSteps = () =>
    steps.map((step, index) => (
      <Step key={step}>
        <StepLabel>{step}</StepLabel>
        <StepContent>{views[index]}</StepContent>
      </Step>
    ));

  /**
   * @name cleanData
   * @description Método encargado de limpiar los datos de ingreso y registro
   */
  const cleanData = () => {
    setData({ email: "", password: "", userName: "", remember: false });
  };

  useEffect(() => {
    if (user) {
      // Avanza a la pantalla de verificación de correo
      setStep(2);
    } else {
      setStep(0);
    }
  }, []);

  return (
    <Box sx={css.box}>
      <div>
        <Label text={lang.registerTitle} fontWeight="500" fontSize="20px" margin="0 0 15px 25px" />
        <Stepper sx={css.stepper} activeStep={step} orientation="vertical">
          {addSteps()}
        </Stepper>
      </div>
      <div>
        <Label text={lang.condition} fontSize="12px" margin="0 25px 10px 25px" />
        <div style={css.footer}>
          {step !== 2 && <ArrowButton index={0} handleClick={handleBack} />}
          {step === 2 ? (
            <p className="clickText" style={css.signUpText} onClick={verified ? onClickToLogin : error ? onClickToSend : onClickToReload}>
              {verified ? lang.back : error ? lang.resend : lang.login}
            </p>
          ) : (
            <ArrowButton index={1} handleClick={handleNext} />
          )}
        </div>
      </div>
      <Message snack={snack}></Message>
    </Box>
  );
};

/**
 * @name styles
 * @description Método encargado de devolver los estilos a los componentes
 * @param {String} primaryColor
 * @returns Object
 * @version 1.0
 */
const styles = (primaryColor) => {
  return {
    stepper: {
      margin: "25px",
      position: "relative",
      "& .MuiStepLabel-root .Mui-active": { color: "#000000" },
      "& .MuiStepLabel-root .Mui-completed": { color: primaryColor },
    },
    footer: { display: "flex", justifyContent: "space-between", margin: "0 25px 0 25px" },
    signUpText: { fontSize: "14px", fontWeight: "500", cursor: "pointer", color: "#4b4b4b", textAlign: "right", width: "100%" },
    box: { maxWidth: window.innerWidth, height: 540, display: "flex", flexDirection: "column", justifyContent: "space-between" },
  };
};

export default StepperVertical;
