import { useThis } from "../../assets/context/Context";
import rotate from "../../images/rotate.svg";
import { Dialog } from "@mui/material";
import "../../css/rotate.css";

/**
 * @name Orientation
 * @description Método que devuelve un componente de tipo Orientation, se muestra mientras la pantalla es landscape
 * @returns View
 * @version 1.0
 */
const Orientation = () => {
  const css = styles();
  const { isPortrait, lang } = useThis();

  return (
    <Dialog sx={css.root} PaperProps={{ style: css.dialog }} fullScreen open={!isPortrait}>
      <div style={css.modal}>
        <img className="rotate" style={css.rotate} src={rotate} alt="rotate" />
        <p style={css.text}>{lang.rotate}</p>
      </div>
    </Dialog>
  );
};

/**
 * @name styles
 * @description Método encargado de devolver los estilos a los componentes
 * @returns Object
 * @version 1.0
 */
const styles = () => {
  return {
    modal: {
      height: "100%",
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      justifyContent: "center",
      backdropFilter: "blur(5px)",
    },
    root: { zIndex: 99999 },
    rotate: { maxHeight: "150px", maxWidth: "150px" },
    dialog: { backgroundColor: "transparent", boxShadow: "none" },
    text: { color: "#FFF", marginTop: "20px", textShadow: "2px 2px 2px black" },
  };
};

export default Orientation;
