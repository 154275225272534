import AlternateEmailIcon from "@mui/icons-material/AlternateEmail";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import { useThis } from "../../assets/context/Context";
import BadgeIcon from "@mui/icons-material/Badge";
import HailIcon from "@mui/icons-material/Hail";
import InputText from "./InputText";
import Selector from "./Selector";
import Label from "./Label";

/**
 * @name AddOrEditUser
 * @description Metodo encargado de mostrar la vista para agregar o editar usuarios
 * @param {Object} { data, setData, nameFocus, emailFocus, permissionsList, readOnly }
 * @returns View
 * @version 1.0
 */
const AddOrEditUser = ({ data, setData, nameFocus, emailFocus, permissionsList, readOnly }) => {
  const css = styles();
  const { lang } = useThis();

  /**
   * @name onKey
   * @description Método encargado de controlar si se presiona enter para consultar ante Hacienda CR la información de la identificación ingresada
   * @param {Event} e
   */
  const onKey = async (e) => {
    if (e.key !== "Enter") return;
  };

  return (
    <div style={css.container}>
      <Label text={lang.personalInfo} fontWeight={"500"} margin={"0 0 15px 0"} />
      <Selector name="typeID" data={data} setData={setData} group={1} />
      <InputText
        data={data}
        marginTop={2}
        onKey={onKey}
        type={"text"}
        size={"small"}
        width={"auto"}
        marginBottom={2}
        setData={setData}
        icon={<BadgeIcon />}
        name="identification"
        label={lang.identification}
        value={data.identification}
      />
      <InputText
        name="name"
        data={data}
        marginTop={2}
        type={"text"}
        size={"small"}
        width={"auto"}
        marginBottom={2}
        label={lang.name}
        value={data.name}
        setData={setData}
        referece={nameFocus}
        icon={<HailIcon />}
      />
      <InputText
        data={data}
        name="email"
        marginTop={2}
        type={"email"}
        size={"small"}
        width={"auto"}
        color={"#872B2B"}
        setData={setData}
        value={data.email}
        readOnly={readOnly}
        referece={emailFocus}
        label={`*${lang.email}`}
        icon={<AlternateEmailIcon />}
      />
      <InputText
        data={data}
        name="phone"
        marginTop={2}
        type={"text"}
        size={"small"}
        width={"auto"}
        setData={setData}
        label={lang.phone}
        value={data.phone}
        icon={<LocalPhoneIcon />}
      />
      <Label text={lang.systemPermissions} fontWeight={"500"} margin={"15px 0"} />
      <div style={css.permissionsHeader}>
        <Label text={lang.permissions} width={"60%"} />
        <Label text={lang.watch} width={"20%"} textAlign={"center"} />
        <Label text={lang.edit} width={"20%"} textAlign={"center"} />
      </div>
      {permissionsList}
    </div>
  );
};

/**
 * @name styles
 * @description Método encargado de devolver los estilos a los componentes
 * @returns Object
 */
const styles = () => {
  return {
    container: { width: "350px" },
    permissionsHeader: { display: "flex", borderBottom: "1px solid #ccc", paddingBottom: "5px", color: "#444" },
  };
};

export default AddOrEditUser;
