import getConstant from "../../assets/js/Constant";
import { Checkbox } from "@mui/material";

/**
 * @name InputCheck
 * @description Método encargado de devolver un componente tipo Checkbox
 * @param {Number} pt - padding-top
 * @param {Number} pl - padding-left
 * @param {Number} pr - padding-right
 * @param {Number} pb - padding-bottom
 * @param {String} size
 * @param {Boolean} value
 * @param {String} name
 * @param {useState} data
 * @param {useState} setData
 * @returns View
 * @version 1.0
 */
const InputCheck = ({ pt = 0, pl = 0, pr = 0, pb = 0, size = "small", value, name, data, setData }) => {
  const constant = getConstant();
  const css = styles(constant.primaryColor);

  /**
   * @name onChange
   * @description Obtiene los datos de los componentes
   * @param {Event} e
   */
  const onChange = (e) => setData({ ...data, [e.target.name]: e.target.checked });

  return <Checkbox name={name} checked={value} size={size} sx={{ ...css.check, pt, pl, pb, pr }} onChange={onChange} />;
};

/**
 * @name styles
 * @description Método que devuelve los estilos
 * @param {String} primaryColor
 * @returns Object
 */
const styles = (primaryColor) => {
  return {
    check: {
      color: "#A7524C",
      "&.Mui-checked": {
        color: primaryColor,
      },
    },
  };
};

export default InputCheck;
