/**
 * @name endpoint
 * @description Objeto que contiene todos los endpoints de la base de datos, necesarios para las consultas.
 * @returns {String} endpoint
 * @version 1.0
 */
const endpoints = {
  branches: (userID) => `/accounts/${userID}/branch`,
  branch: (userID, branchID) => `/accounts/${userID}/branch/${branchID}`,
  users: (userID, branchID) => `/accounts/${userID}/branch/${branchID}/users`,
  services: (userID, branchID) => `/accounts/${userID}/branch/${branchID}/services`,
  products: (userID, branchID) => `/accounts/${userID}/branch/${branchID}/products`,
  user: (userID, branchID, id) => `/accounts/${userID}/branch/${branchID}/users/${id}`,
  categories: (userID, branchID) => `/accounts/${userID}/branch/${branchID}/categories`,
  subscriptions: (userID, branchID) => `/accounts/${userID}/branch/${branchID}/subscriptions`,
  storeLogo: (userID, branchID, fileName) => `/accounts/${userID}/${branchID}/images/logo/${fileName}`,
  product: (userID, branchID, productID) => `/accounts/${userID}/branch/${branchID}/products/${productID}`,
  category: (userID, branchID, categoryID) => `/accounts/${userID}/branch/${branchID}/categories/${categoryID}`,
  storeCategories: (userID, branchID, fileName) => `/accounts/${userID}/${branchID}/images/categories/${fileName}`,
  storeSubDoc: (userID, branchID, type, fileName) => `/accounts/${userID}/${branchID}/images/subscriptions/${type}/${fileName}`,
};

export default endpoints;
