import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { useThis } from "../../assets/context/Context";
import ChatIcon from "@mui/icons-material/Chat";
import InputText from "./InputText";
import Label from "./Label";

const AddIncreasesOrDecreases = ({ dataAction, setDataAction, amountInventoryFocus }) => {
  const css = styles();
  const { lang } = useThis();

  const handleChangeAction = (e, value) => {
    if (!value) return;
    setDataAction({ ...dataAction, action: e.target.id, actionCode: value });
    console.log(value);
    if (value === "action2") {
      setDataAction((prev) => ({ ...prev, reason: lang.loss, reasonCode: "reason3" }));
    } else {
      setDataAction((prev) => ({ ...prev, reason: lang.stock, reasonCode: "reason1" }));
    }
  };

  const handleChangeReason = (e, value) => {
    if (!value) return;
    setDataAction({ ...dataAction, reason: e.target.id, reasonCode: value });
  };

  return (
    <div>
      <div style={css.item}>
        <Label text={lang.item + ":"} fontWeight={600} margin={"0 5px 10px 0"} />
        <Label text={dataAction.item} />
      </div>
      <InputText
        width={"auto"}
        size={"small"}
        type={"number"}
        name={"amount"}
        marginBottom={1}
        data={dataAction}
        label={lang.amount}
        setData={setDataAction}
        value={dataAction.name}
        referece={amountInventoryFocus}
        icon={<FormatListNumberedIcon />}
      />
      <div style={css.item}>
        <Label text={lang.action + ":"} fontWeight={600} margin={"0 5px 5px 0"} />
        <Label text={dataAction.action} />
      </div>
      <ToggleButtonGroup size="small" sx={{ width: "100%" }} color="primary" value={dataAction.actionCode} exclusive onChange={handleChangeAction}>
        <ToggleButton sx={{ width: "100%" }} id={lang.increases} value="action1">
          {lang.increases}
        </ToggleButton>
        <ToggleButton sx={{ width: "100%" }} id={lang.decreases} value="action2">
          {lang.decreases}
        </ToggleButton>
      </ToggleButtonGroup>
      <div style={css.item}>
        <Label text={lang.reason + ":"} fontWeight={600} margin={"5px 5px 5px 0"} />
        <Label text={dataAction.reason} margin={"5px 5px 5px 0"} />
      </div>
      <ToggleButtonGroup size="small" sx={{ width: "100%" }} color="primary" value={dataAction.reasonCode} exclusive onChange={handleChangeReason}>
        {dataAction.actionCode === "action1" ? (
          <ToggleButton sx={{ width: "100%" }} id={lang.stock} value="reason1">
            {lang.stock}
          </ToggleButton>
        ) : (
          <ToggleButton sx={{ width: "100%" }} id={lang.loss} value="reason3">
            {lang.loss}
          </ToggleButton>
        )}
        <ToggleButton sx={{ width: "100%" }} id={lang.adjustment} value="reason2">
          {lang.adjustment}
        </ToggleButton>
      </ToggleButtonGroup>
      <InputText
        rows={2}
        name={"obs"}
        marginTop={2}
        type={"text"}
        width={"auto"}
        size={"small"}
        label={lang.obs}
        data={dataAction}
        icon={<ChatIcon />}
        value={dataAction.obs}
        setData={setDataAction}
      />
    </div>
  );
};

/**
 * @name styles
 * @description Método encargado de devolver los estilos a los componentes
 * @returns Object
 * @version 1.0
 */
const styles = () => {
  return {
    item: { display: "flex" },
  };
};

export default AddIncreasesOrDecreases;
