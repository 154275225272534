import { FolderSpecial, ImageNotSupported } from "@mui/icons-material";
import { useThis } from "../../assets/context/Context";
import getConstant from "../../assets/js/Constant";
import InputFileUpload from "./InputFileUpload";
import defalut from "../../images/default.svg";
import LoadingButton from "./LoadingButton";
import InputText from "./InputText";

/**
 * @name AddCategory
 * @description Muestra los componentes del modal para agregar categorías
 * @param {Object} { data, setData }
 * @returns View
 * @version 1.0
 */
const AddCategory = ({ data, setData, nameFocus }) => {
  const constant = getConstant();
  const css = styles(data);
  const { lang } = useThis();

  /**
   * @name removeImage
   * @description Método que remueve la imagen del contenedor
   * @returns N/A
   * @version 1.0
   */
  const removeImage = () => setData({ ...data, file: null, url: "" });

  return (
    <div style={css.container}>
      <InputText
        data={data}
        type={"text"}
        size={"small"}
        width={"auto"}
        marginBottom={1}
        setData={setData}
        name={"category"}
        referece={nameFocus}
        label={lang.category}
        value={data.category}
        icon={<FolderSpecial />}
      />
      <img src={data.url ? data.url : defalut} alt="" style={css.img} />
      <div style={css.buttons}>
        {(data.file || data.url) && (
          <LoadingButton text={lang.remove} handleClick={removeImage} icon={<ImageNotSupported />} color={constant.deleteColor} />
        )}
        <InputFileUpload name="url" text={lang.image} accept={constant.onlyImage} color={"#666"} data={data} setData={setData} />
      </div>
    </div>
  );
};

/**
 * @name styles
 * @description Método encargado de devolver los estilos a los componentes
 * @param {Object} data
 * @returns Object
 * @version 1.0
 */
const styles = (data) => {
  return {
    container: { display: "flex", flexDirection: "column" },
    buttons: { display: "flex", justifyContent: data.file || data.url ? "space-between" : "right" },
    img: { width: "280px", height: "280px ", border: "1px solid #CCC", marginBottom: "3px", objectFit: "cover" },
  };
};

export default AddCategory;
