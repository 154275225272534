/**
 * @name COSTA_RICA
 * @description Función que devuelve las provincias, cantones y distritos de Costa Rica
 * @returns Object con las direcciónes de Costa Rica
 */
const COSTA_RICA = () => [
  {
    name: "San José",
    id: 1,
    cantons: [
      {
        name: "San José",
        id: 101,
        district: [
          { name: "Carmen", id: 10101 },
          { name: "Merced", id: 10102 },
          { name: "Hospital", id: 10103 },
          { name: "Catedral", id: 10104 },
          { name: "Zapote", id: 10105 },
          { name: "San Francisco de Dos Ríos", id: 10106 },
          { name: "Uruca", id: 10107 },
          { name: "Mata Redonda", id: 10108 },
          { name: "Pavas", id: 10109 },
          { name: "Hatillo", id: 10110 },
          { name: "San Sebastián", id: 10111 },
        ],
      },
      {
        name: "Escazú",
        id: 102,
        district: [
          { name: "Escazú", id: 10201 },
          { name: "San Antonio", id: 10202 },
          { name: "San Rafael", id: 10203 },
        ],
      },
      {
        name: "Desamparados",
        id: 103,
        district: [
          { name: "Desamparados", id: 10301 },
          { name: "San Miguel", id: 10302 },
          { name: "San Juan de Dios", id: 10303 },
          { name: "San Rafael Arriba", id: 10304 },
          { name: "San Antonio", id: 10305 },
          { name: "Frailes", id: 10306 },
          { name: "Patarra", id: 10308 },
          { name: "San Cristobal", id: 10307 },
          { name: "Rosario", id: 10309 },
          { name: "Damas", id: 10310 },
          { name: "San Rafael Abajo", id: 10311 },
          { name: "Gravilias", id: 10312 },
          { name: "Los Guido", id: 10313 },
        ],
      },
      {
        name: "Puriscal",
        id: 104,
        district: [
          { name: "Santiago", id: 10401 },
          { name: "Mercedes Sur", id: 10402 },
          { name: "Barbacoas", id: 10403 },
          { name: "Grifo Alto", id: 10404 },
          { name: "San Rafael", id: 10405 },
          { name: "Candelarita", id: 10406 },
          { name: "Desamparaditos", id: 10407 },
          { name: "San Antonio", id: 10408 },
          { name: "Chires", id: 10409 },
        ],
      },
      {
        name: "Tarrazú",
        id: 105,
        district: [
          { name: "San Marcos", id: 10501 },
          { name: "San Lorenzo", id: 10502 },
          { name: "San Carlos", id: 10503 },
        ],
      },
      {
        name: "Aserrí",
        id: 106,
        district: [
          { name: "Aserrí", id: 10601 },
          { name: "Tarbaca", id: 10602 },
          { name: "Vuelta de Jorco", id: 10603 },
          { name: "San Gabriel", id: 10604 },
          { name: "Legua", id: 10605 },
          { name: "Monterrey", id: 10606 },
          { name: "Salitrillos", id: 10607 },
        ],
      },
      {
        name: "Mora",
        id: 107,
        district: [
          { name: "Colón", id: 10701 },
          { name: "Guayabo", id: 10702 },
          { name: "Tabarcia", id: 10703 },
          { name: "Piedras Negras", id: 10704 },
          { name: "Picagres", id: 10705 },
          { name: "Jaris", id: 10706 },
          { name: "Quitirrisí", id: 10707 },
        ],
      },
      {
        name: "Goicochea",
        id: 108,
        district: [
          { name: "Guadalupe", id: 10801 },
          { name: "San Francisco", id: 10802 },
          { name: "Calle Blancos", id: 10803 },
          { name: "Mata de Plátano", id: 10804 },
          { name: "Ipis", id: 10805 },
          { name: "Rancho Redondo", id: 10806 },
          { name: "Purral", id: 10807 },
        ],
      },
      {
        name: "Santa Ana",
        id: 109,
        district: [
          { name: "Santa Ana", id: 10901 },
          { name: "Salitral", id: 10902 },
          { name: "Pozos", id: 10903 },
          { name: "Uruca", id: 10904 },
          { name: "Piedades", id: 10905 },
          { name: "Brasil", id: 10906 },
        ],
      },
      {
        name: "Alajuelita",
        id: 110,
        district: [
          { name: "Alajuelita", id: 11001 },
          { name: "San Josecito", id: 11002 },
          { name: "San Antonio", id: 11003 },
          { name: "Concepción", id: 11004 },
          { name: "San Felipe", id: 11005 },
        ],
      },
      {
        name: "Vázquez de Coronado",
        id: 111,
        district: [
          { name: "San Isidro", id: 11101 },
          { name: "San Rafael", id: 11102 },
          { name: "Dulce Nombre de Jesús", id: 11103 },
          { name: "Patalillo", id: 11104 },
          { name: "Cascajal", id: 11105 },
        ],
      },
      {
        name: "Acosta",
        id: 112,
        district: [
          { name: "San Ignacio", id: 11201 },
          { name: "Guaitil", id: 11202 },
          { name: "Palmichal", id: 11203 },
          { name: "Cangrejal", id: 11204 },
          { name: "Sabanillas", id: 11205 },
        ],
      },
      {
        name: "Tibás",
        id: 113,
        district: [
          { name: "San Juan", id: 11301 },
          { name: "Cinco Esquinas", id: 11302 },
          { name: "Anselmo Llorente", id: 11303 },
          { name: "León XIII", id: 11304 },
          { name: "Colima", id: 11305 },
        ],
      },
      {
        name: "Moravia",
        id: 114,
        district: [
          { name: "San Vicente", id: 11401 },
          { name: "San Jerónimo", id: 11402 },
          { name: "La Trinidad", id: 11403 },
        ],
      },
      {
        name: "Montes de Oca",
        id: 115,
        district: [
          { name: "San Pedro", id: 11501 },
          { name: "Sabanilla", id: 11502 },
          { name: "Mercedes", id: 11503 },
          { name: "San Rafael", id: 11504 },
        ],
      },
      {
        name: "Turrubares",
        id: 116,
        district: [
          { name: "San Pablo", id: 11601 },
          { name: "San Pedro", id: 11602 },
          { name: "San Juan de Mata", id: 11603 },
          { name: "San Luis", id: 11604 },
          { name: "Carara", id: 11605 },
        ],
      },
      {
        name: "Dota",
        id: 117,
        district: [
          { name: "Santa María", id: 11701 },
          { name: "Jardín", id: 11702 },
          { name: "Copey", id: 11703 },
        ],
      },
      {
        name: "Curridabat",
        id: 118,
        district: [
          { name: "Curridabat", id: 11801 },
          { name: "Granadilla", id: 11802 },
          { name: "Sánchez", id: 11803 },
          { name: "Tirrases", id: 11804 },
        ],
      },
      {
        name: "Pérez Zeledón",
        id: 119,
        district: [
          { name: "San Isidro de El General", id: 11901 },
          { name: "El General", id: 11902 },
          { name: "Daniel Flores", id: 11903 },
          { name: "Rivas", id: 11904 },
          { name: "San Pedro", id: 11905 },
          { name: "Platanares", id: 11906 },
          { name: "Pejibaye", id: 11907 },
          { name: "Cajón", id: 11908 },
          { name: "Barú", id: 11909 },
          { name: "Río Nuevo", id: 11910 },
          { name: "Paramo", id: 11911 },
          { name: "La  Amistad", id: 11912 },
        ],
      },
      {
        name: "León Cortés Castro",
        id: 120,
        district: [
          { name: "San Pablo", id: 12001 },
          { name: "San Andrés", id: 12002 },
          { name: "Llano Bonito", id: 12003 },
          { name: "San Isidro", id: 12004 },
          { name: "Santa Cruz", id: 12005 },
          { name: "San Antonio", id: 12006 },
        ],
      },
    ],
  },
  {
    name: "Alajuela",
    id: 2,
    cantons: [
      {
        name: "Alajuela",
        id: 201,
        district: [
          { name: "Alajuela", id: 20101 },
          { name: "San José", id: 20102 },
          { name: "Carrizal", id: 20103 },
          { name: "San Antonio", id: 20104 },
          { name: "Guácima", id: 20105 },
          { name: "San Isidro", id: 20106 },
          { name: "Sabanilla", id: 20107 },
          { name: "San Rafael", id: 20108 },
          { name: "Río Segundo", id: 20109 },
          { name: "Desamparados", id: 20110 },
          { name: "Turrucares", id: 20111 },
          { name: "Tambor", id: 20112 },
          { name: "Garita", id: 20113 },
          { name: "Sarapiquí", id: 20114 },
        ],
      },
      {
        name: "San Ramón",
        id: 202,
        district: [
          { name: "San Ramón", id: 20201 },
          { name: "Santiago", id: 20202 },
          { name: "San Juann", id: 20203 },
          { name: "Piedades Norte", id: 20204 },
          { name: "Piedades Sur", id: 20205 },
          { name: "San Rafael", id: 20206 },
          { name: "San Isidro", id: 20207 },
          { name: "Ángeles", id: 20208 },
          { name: "Alfaro", id: 20209 },
          { name: "Volio", id: 20210 },
          { name: "Concepción", id: 20211 },
          { name: "Zapotal", id: 20212 },
          { name: "Peñas Blancas", id: 20213 },
          { name: "San Lorenzo", id: 20214 },
        ],
      },
      {
        name: "Grecia",
        id: 203,
        district: [
          { name: "Grecia", id: 20301 },
          { name: "San Isidro", id: 20302 },
          { name: "San José", id: 20303 },
          { name: "San Roque", id: 20304 },
          { name: "Tacares", id: 20305 },
          { name: "Puente de Piedra", id: 20307 },
          { name: "Bolivar", id: 20308 },
        ],
      },
      {
        name: "San Mateo",
        id: 204,
        district: [
          { name: "San Mateo", id: 20401 },
          { name: "Desmonte", id: 20402 },
          { name: "Jesús María", id: 20403 },
          { name: "Labrador", id: 20404 },
        ],
      },
      {
        name: "Atenas",
        id: 205,
        district: [
          { name: "Atenas", id: 20501 },
          { name: "Jesús", id: 20502 },
          { name: "Mercedes", id: 20503 },
          { name: "San Isidro", id: 20504 },
          { name: "Concepción", id: 20505 },
          { name: "San José", id: 20506 },
          { name: "Santa Eulalia", id: 20507 },
          { name: "Escobal", id: 20508 },
        ],
      },
      {
        name: "Naranjo",
        id: 206,
        district: [
          { name: "Naranjo", id: 20601 },
          { name: "San Miguel", id: 20602 },
          { name: "San José", id: 20603 },
          { name: "Cirrí Sur", id: 20604 },
          { name: "San Jerónimo", id: 20605 },
          { name: "San Juan", id: 20606 },
          { name: "El Rosario", id: 20607 },
          { name: "Palmitos", id: 20608 },
        ],
      },
      {
        name: "Palmares",
        id: 207,
        district: [
          { name: "Palmares", id: 20701 },
          { name: "Zaragoza", id: 20702 },
          { name: "Buenos Aires", id: 20703 },
          { name: "Santiago", id: 20704 },
          { name: "Candelaria", id: 20705 },
          { name: "Esquipulas", id: 20706 },
          { name: "La Granja", id: 20707 },
        ],
      },
      {
        name: "Poás",
        id: 208,
        district: [
          { name: "San Pedro", id: 20801 },
          { name: "San Juan", id: 20802 },
          { name: "San Rafael", id: 20803 },
          { name: "Carrillos", id: 20804 },
          { name: "Sabana Redonda", id: 20805 },
        ],
      },
      {
        name: "Orotina",
        id: 209,
        district: [
          { name: "Orotina", id: 20901 },
          { name: "El Mastate", id: 20902 },
          { name: "Hacienda Vieja", id: 20903 },
          { name: "Coyolar", id: 20904 },
          { name: "La Ceiba", id: 20905 },
        ],
      },
      {
        name: "San Carlos",
        id: 210,
        district: [
          { name: "Quesada", id: 21001 },
          { name: "Florencia", id: 21002 },
          { name: "Buenavista", id: 21003 },
          { name: "Aguas Zarcas", id: 21004 },
          { name: "Venecia", id: 21005 },
          { name: "Pital", id: 21006 },
          { name: "La Fortuna", id: 21007 },
          { name: "La Tigra", id: 21008 },
          { name: "La Palmera", id: 21009 },
          { name: "Venado", id: 21010 },
          { name: "Cutris", id: 21011 },
          { name: "Monterrey", id: 21012 },
          { name: "Pocosol", id: 21013 },
        ],
      },
      {
        name: "Zarcero",
        id: 211,
        district: [
          { name: "Zarcero", id: 21101 },
          { name: "Laguna", id: 21102 },
          { name: "Tapesco", id: 21103 },
          { name: "Guadalupe", id: 21104 },
          { name: "Palmira", id: 21104 },
          { name: "Zapote", id: 21106 },
          { name: "Brisas", id: 21107 },
        ],
      },
      {
        name: "Sarchí",
        id: 212,
        district: [
          { name: "Sarchí Norte", id: 21201 },
          { name: "Sarchí Sur", id: 21202 },
          { name: "Toro Amarillo", id: 21203 },
          { name: "San Pedro", id: 21204 },
          { name: "Rodríguez", id: 21205 },
        ],
      },
      {
        name: "Upala",
        id: 213,
        district: [
          { name: "Upala", id: 21301 },
          { name: "Aguas Claras", id: 21302 },
          { name: "San José O Pizote", id: 21303 },
          { name: "Bijagua", id: 21304 },
          { name: "Delicias", id: 21305 },
          { name: "Dos Ríos", id: 21306 },
          { name: "Yolillal", id: 21307 },
          { name: "Canalete", id: 21308 },
        ],
      },
      {
        name: "Los Chiles",
        id: 214,
        district: [
          { name: "Los Chiles", id: 21401 },
          { name: "Caño Negro", id: 21402 },
          { name: "El Amparo", id: 21403 },
          { name: "San Jorge", id: 21404 },
        ],
      },
      {
        name: "Guatuso",
        id: 215,
        district: [
          { name: "San Rafael", id: 21501 },
          { name: "Buenavista", id: 21502 },
          { name: "Cote", id: 21503 },
          { name: "Katira", id: 21504 },
        ],
      },
      {
        name: "Río Cuarto",
        id: 216,
        district: [
          { name: "Río Cuarto", id: 21601 },
          { name: "Santa Rita", id: 21602 },
          { name: "Santa Isabel", id: 21603 },
        ],
      },
    ],
  },
  {
    name: "Cartago",
    id: 3,
    cantons: [
      {
        name: "Cartago",
        id: 301,
        district: [
          { name: "Oriental", id: 30101 },
          { name: "Occidental", id: 30102 },
          { name: "Carmen", id: 30103 },
          { name: "San Nicolás", id: 30104 },
          { name: "Aguacaliente o San Francisco", id: 30105 },
          { name: "Guadalupe o Arenilla", id: 30106 },
          { name: "Corralillo", id: 30107 },
          { name: "Tierra Blanca", id: 30108 },
          { name: "Dulce Nombre", id: 30109 },
          { name: "Llano Grande", id: 30110 },
          { name: "Quebradilla", id: 30111 },
        ],
      },
      {
        name: "Paraíso",
        id: 302,
        district: [
          { name: "Paraíso", id: 30201 },
          { name: "Santiago", id: 30202 },
          { name: "Orosi", id: 30203 },
          { name: "Cachí", id: 30204 },
          { name: "Llanos de Santa Lucía", id: 30205 },
          { name: "Birrisito", id: 30206 },
        ],
      },
      {
        name: "La Unión",
        id: 303,
        district: [
          { name: "Tres Ríos", id: 30301 },
          { name: "San Diego", id: 30302 },
          { name: "San Juan", id: 30303 },
          { name: "San Rafael", id: 30304 },
          { name: "Concepción", id: 30305 },
          { name: "Dulce Nombre", id: 30306 },
          { name: "San Ramón", id: 30307 },
          { name: "Río Azul", id: 30308 },
        ],
      },
      {
        name: "Jiménez",
        id: 304,
        district: [
          { name: "Juan Viñas", id: 30401 },
          { name: "Tucurrique", id: 30402 },
          { name: "Pejibaye", id: 30403 },
        ],
      },
      {
        name: "Turrialba",
        id: 305,
        district: [
          { name: "Turrialba", id: 30501 },
          { name: "La Suiza", id: 30502 },
          { name: "Peralta", id: 30503 },
          { name: "Santa Cruz", id: 30504 },
          { name: "Santa Teresita", id: 30505 },
          { name: "Pavones", id: 30506 },
          { name: "Tuis", id: 30507 },
          { name: "Tayutic", id: 30508 },
          { name: "Santa Rosa", id: 30509 },
          { name: "Tres Equis", id: 30510 },
          { name: "La Isabel", id: 30511 },
          { name: "Chirripó", id: 30512 },
        ],
      },
      {
        name: "Alvarado",
        id: 306,
        district: [
          { name: "Pacayas", id: 30601 },
          { name: "Cervantes", id: 30602 },
          { name: "Capellades", id: 30603 },
        ],
      },
      {
        name: "Oreamuno",
        id: 307,
        district: [
          { name: "San Rafael", id: 30701 },
          { name: "Cot", id: 30702 },
          { name: "Potrero Cerrado", id: 30703 },
          { name: "Cipreses", id: 30704 },
          { name: "Santa Rosa", id: 30705 },
        ],
      },
      {
        name: "El Guarco",
        id: 308,
        district: [
          { name: "El Tejar", id: 30801 },
          { name: "San Isidro", id: 30802 },
          { name: "Tobosi", id: 30803 },
          { name: "Patio de Agua", id: 30804 },
        ],
      },
    ],
  },
  {
    name: "Heredia",
    id: 4,
    cantons: [
      {
        name: "Heredia",
        id: 401,
        district: [
          { name: "Heredia", id: 40101 },
          { name: "Mercedes", id: 40102 },
          { name: "San Francisco", id: 40103 },
          { name: "Ulloa", id: 40104 },
          { name: "Varablanca", id: 40105 },
        ],
      },
      {
        name: "Barva",
        id: 402,
        district: [
          { name: "Barva", id: 40201 },
          { name: "San Pedro", id: 40202 },
          { name: "San Pablo", id: 40203 },
          { name: "San Roque", id: 40204 },
          { name: "Santa Lucía", id: 40205 },
          { name: "San José de la Montaña", id: 40206 },
        ],
      },
      {
        name: "Santo Domingo",
        id: 403,
        district: [
          { name: "Santo Domingo", id: 40301 },
          { name: "San Vicente", id: 40302 },
          { name: "San Miguel", id: 40303 },
          { name: "Parasito", id: 40304 },
          { name: "Santo Tomás", id: 40305 },
          { name: "Santa Rosa", id: 40306 },
          { name: "Tures", id: 40307 },
          { name: "Pará", id: 40308 },
        ],
      },
      {
        name: "Santa Bárbara",
        id: 404,
        district: [
          { name: "Santa Bárbara", id: 40401 },
          { name: "San Pedro", id: 40402 },
          { name: "San Juan", id: 40403 },
          { name: "Jesús", id: 40404 },
          { name: "Santo Domingo", id: 40405 },
          { name: "Purabá", id: 40406 },
        ],
      },
      {
        name: "San Rafael",
        id: 405,
        district: [
          { name: "San Rafael", id: 40501 },
          { name: "San Josecito", id: 40502 },
          { name: "Santiago", id: 40503 },
          { name: "Ángeles", id: 40504 },
          { name: "Concepción", id: 40505 },
        ],
      },
      {
        name: "San Isidro",
        id: 406,
        district: [
          { name: "San Isidro", id: 40601 },
          { name: "San José", id: 40602 },
          { name: "Concepción", id: 40603 },
          { name: "San Francisco", id: 40604 },
        ],
      },
      {
        name: "Belén",
        id: 407,
        district: [
          { name: "San Antonio", id: 40701 },
          { name: "La Ribera", id: 40702 },
          { name: "La Asunción", id: 40703 },
        ],
      },
      {
        name: "Flores",
        id: 408,
        district: [
          { name: "San Joaquín", id: 40801 },
          { name: "Barrantes", id: 40802 },
          { name: "Llorente", id: 40803 },
        ],
      },
      {
        name: "San Pablo",
        id: 409,
        district: [
          { name: "San Pablo", id: 40901 },
          { name: "Rincón de Sabanilla", id: 40902 },
        ],
      },
      {
        name: "Sarapiquí",
        id: 410,
        district: [
          { name: "Puerto Viejo", id: 41001 },
          { name: "La Virgen", id: 41002 },
          { name: "Las Horquetas", id: 41003 },
          { name: "Llanuras del Gaspar", id: 41004 },
          { name: "Cureña", id: 41005 },
        ],
      },
    ],
  },
  {
    name: "Guanacaste",
    id: 5,
    cantons: [
      {
        name: "Liberia",
        id: 501,
        district: [
          { name: "Liberia", id: 50101 },
          { name: "Cañas Dulces", id: 50102 },
          { name: "Mayorga", id: 50103 },
          { name: "Nacascolo", id: 50104 },
          { name: "Curubandé", id: 50105 },
        ],
      },
      {
        name: "Nicoya",
        id: 502,
        district: [
          { name: "Nicoya", id: 50201 },
          { name: "Mansión", id: 50202 },
          { name: "San Antonio", id: 50203 },
          { name: "Quebrada Honda", id: 50204 },
          { name: "Sámara", id: 50205 },
          { name: "Nosara", id: 50206 },
          { name: "Belén de Nosarita", id: 50207 },
        ],
      },
      {
        name: "Santa Cruz",
        id: 503,
        district: [
          { name: "Santa Cruz", id: 50301 },
          { name: "Bolsón", id: 50302 },
          { name: "Veintisiete de Abril", id: 50303 },
          { name: "Tempate", id: 50304 },
          { name: "Cartagena", id: 50305 },
          { name: "Cuajiniquil", id: 50306 },
          { name: "Diriá", id: 50307 },
          { name: "Cabo Velas", id: 50308 },
          { name: "Tamarindo", id: 50309 },
        ],
      },
      {
        name: "Bagaces",
        id: 504,
        district: [
          { name: "Bagaces", id: 50401 },
          { name: "La Fortuna", id: 50402 },
          { name: "Mogote", id: 50403 },
          { name: "Río Naranjo", id: 50404 },
        ],
      },
      {
        name: "Carrillo",
        id: 505,
        district: [
          { name: "Filadelfia", id: 50501 },
          { name: "Palmira", id: 50502 },
          { name: "Serdinal", id: 50503 },
          { name: "Belén", id: 50504 },
        ],
      },
      {
        name: "Cañas",
        id: 506,
        district: [
          { name: "Cañas", id: 50601 },
          { name: "Palmira", id: 50602 },
          { name: "San Miguel", id: 50603 },
          { name: "Babedero", id: 50604 },
          { name: "Porozal", id: 50605 },
        ],
      },
      {
        name: "Abangares",
        id: 507,
        district: [
          { name: "Las Juntas", id: 50701 },
          { name: "Sierra", id: 50702 },
          { name: "San Juan", id: 50703 },
          { name: "Colorado", id: 50704 },
        ],
      },
      {
        name: "Tilarán",
        id: 508,
        district: [
          { name: "Tilarán", id: 50801 },
          { name: "Quebrada Grande", id: 50802 },
          { name: "Tronadora", id: 50803 },
          { name: "Santa Rosa", id: 50804 },
          { name: "Líbano", id: 50805 },
          { name: "Tierras Morenas", id: 50806 },
          { name: "Arenal", id: 50807 },
          { name: "Cabeceras", id: 50808 },
        ],
      },
      {
        name: "Nandayure",
        id: 509,
        district: [
          { name: "Carmona", id: 50901 },
          { name: "Santa Rita", id: 50902 },
          { name: "Zapotal", id: 50903 },
          { name: "San Pablo", id: 50904 },
          { name: "Porvenir", id: 50905 },
          { name: "Bejuco", id: 50906 },
        ],
      },
      {
        name: "La Cruz",
        id: 510,
        district: [
          { name: "La Cruz", id: 51001 },
          { name: "Santa Cecilia", id: 51002 },
          { name: "La Garita", id: 51003 },
          { name: "Santa Elena", id: 51004 },
        ],
      },
      {
        name: "Hoja Ancha",
        id: 511,
        district: [
          { name: "Hoja Ancha", id: 51101 },
          { name: "Monte Roma", id: 51102 },
          { name: "Puerto Carrillo", id: 51103 },
          { name: "Huacas", id: 51104 },
          { name: "Matambú", id: 51105 },
        ],
      },
    ],
  },
  {
    name: "Puntarenas",
    id: 6,
    cantons: [
      {
        name: "Puntarenas",
        id: 601,
        district: [
          { name: "Puntarenas", id: 60101 },
          { name: "Pithaya", id: 60102 },
          { name: "Chomes", id: 60103 },
          { name: "Lepanto", id: 60104 },
          { name: "Paquera", id: 60105 },
          { name: "Manzanillo", id: 60106 },
          { name: "Guacimal", id: 60107 },
          { name: "Barranca", id: 60108 },
          { name: "Isla del Coco", id: 60110 },
          { name: "Cóbano", id: 60111 },
          { name: "Chacarita", id: 60112 },
          { name: "Chira", id: 60113 },
          { name: "Acapulco", id: 60114 },
          { name: "El Roble", id: 60115 },
          { name: "Arancibia", id: 60116 },
        ],
      },
      {
        name: "Esparza",
        id: 602,
        district: [
          { name: "Espíritu Santo", id: 60201 },
          { name: "San Juan Grande", id: 60202 },
          { name: "Macacona", id: 60203 },
          { name: "San Rafael", id: 60204 },
          { name: "San Jerónimo", id: 60205 },
          { name: "Caldera", id: 60206 },
        ],
      },
      {
        name: "Buenos Aires",
        id: 603,
        district: [
          { name: "Buenos Aires", id: 60301 },
          { name: "Volcán", id: 60302 },
          { name: "Potrero Grande", id: 60303 },
          { name: "Boruca", id: 60304 },
          { name: "Pilas", id: 60305 },
          { name: "Colinas", id: 60306 },
          { name: "Chánguena", id: 60307 },
          { name: "Biolley", id: 60308 },
          { name: "Brunka", id: 60309 },
        ],
      },
      {
        name: "Montes de Oro",
        id: 604,
        district: [
          { name: "Miramar", id: 60401 },
          { name: "La Unión", id: 60402 },
          { name: "San Isidro", id: 60403 },
        ],
      },
      {
        name: "Osa",
        id: 605,
        district: [
          { name: "Puerto Cortés", id: 60501 },
          { name: "Palmar", id: 60502 },
          { name: "Sierpe", id: 60503 },
          { name: "Bahía Ballena", id: 60504 },
          { name: "Piedras Blancas", id: 60505 },
          { name: "Bahía Drake", id: 60506 },
        ],
      },
      {
        name: "Quepos",
        id: 606,
        district: [
          { name: "Quepos", id: 60601 },
          { name: "Savegre", id: 60602 },
          { name: "Naranjito", id: 60603 },
        ],
      },
      {
        name: "Golfio",
        id: 607,
        district: [
          { name: "Golfio", id: 60701 },
          { name: "Guaycará", id: 60703 },
          { name: "Pavón", id: 60704 },
        ],
      },
      {
        name: "Coto Brus",
        id: 608,
        district: [
          { name: "San Vito", id: 60801 },
          { name: "Sabalito", id: 60802 },
          { name: "Agua Buena", id: 60803 },
          { name: "Limoncito", id: 60804 },
          { name: "Pittier", id: 60805 },
          { name: "Gutiérrez Braun", id: 60806 },
        ],
      },
      { name: "Parrita", id: 609, district: [{ name: "Parrita", id: 60901 }] },
      {
        name: "Corredores",
        id: 610,
        district: [
          { name: "Corredor", id: 61001 },
          { name: "La Cuesta", id: 61002 },
          { name: "Canoas", id: 61003 },
          { name: "Laurel", id: 61004 },
        ],
      },
      {
        name: "Garabito",
        id: 611,
        district: [
          { name: "Jacó", id: 61101 },
          { name: "Tárcoles", id: 61102 },
          { name: "Lagunillas", id: 61103 },
        ],
      },
      { name: "Monte Verde", id: 612, district: [{ name: "Monte Verde", id: 61201 }] },
      { name: "Puerto Jiménez", id: 613, district: [{ name: "Puerto Jiménez", id: 61301 }] },
    ],
  },
  {
    name: "Limón",
    id: 7,
    cantons: [
      {
        name: "Limón",
        id: 701,
        district: [
          { name: "Limón", id: 70101 },
          { name: "Valle la Estrella", id: 70102 },
          { name: "Río Blanco", id: 70103 },
          { name: "Matama", id: 70104 },
        ],
      },
      {
        name: "Pococí",
        id: 702,
        district: [
          { name: "Guápiles", id: 70201 },
          { name: "Jiménez", id: 70202 },
          { name: "Rita", id: 70203 },
          { name: "Roxana", id: 70204 },
          { name: "Cariari", id: 70205 },
          { name: "Colorado", id: 70206 },
          { name: "La Colonia", id: 70207 },
        ],
      },
      {
        name: "Siquirres",
        id: 703,
        district: [
          { name: "Siquirres", id: 70301 },
          { name: "Pacuarito", id: 70302 },
          { name: "Florida", id: 70303 },
          { name: "Germania", id: 70304 },
          { name: "El Cairo", id: 70305 },
          { name: "Alegría", id: 70306 },
          { name: "Reventazón", id: 70307 },
        ],
      },
      {
        name: "Talamanca",
        id: 704,
        district: [
          { name: "Bratsi", id: 70401 },
          { name: "Sixaola", id: 70402 },
          { name: "Cahuita", id: 70403 },
          { name: "Telire", id: 70404 },
        ],
      },
      {
        name: "Matina",
        id: 705,
        district: [
          { name: "Matina", id: 70501 },
          { name: "Batán", id: 70502 },
          { name: "Barrandí", id: 70503 },
        ],
      },
      {
        name: "Guácimo",
        id: 706,
        district: [
          { name: "Guácimo", id: 70601 },
          { name: "Mercedes", id: 70602 },
          { name: "Pocora", id: 70603 },
          { name: "Río Jiménez", id: 70604 },
          { name: "Duacarí", id: 70605 },
        ],
      },
    ],
  },
];

export default COSTA_RICA;
