import { Button, ThemeProvider, createTheme } from "@mui/material";

/**
 * @name AccountButton
 * @description Método encargado de devolver un componente tipo Button
 * @param {ReactElement} icon
 * @param {ReactElement} text
 * @param {ReactElement} mt
 * @param {ReactElement} mb
 * @param {ReactElement} width
 * @param {ReactElement} handleClick
 * @returns View
 * @version 1.0
 */
const AccountButton = ({ icon, text, mt, mb, width, handleClick }) => {
  const css = styles(mt, mb);
  return (
    <div style={{ width }}>
      <ThemeProvider theme={theme}>
        <Button color="classic" startIcon={icon} variant="outlined" onClick={handleClick} sx={css.btn}>
          {text}
        </Button>
      </ThemeProvider>
    </div>
  );
};

/**
 * @name theme
 * @description Método encargado de crear el estilo para el input
 */
const theme = createTheme({
  palette: {
    classic: {
      main: "#444444",
    },
  },
});

/**
 * @name styles
 * @description Método encargado de devolver los estilos a los componentes
 * @returns Object
 */
const styles = (mt, mb) => {
  return {
    btn: { mt, mb, width: "100%", justifyContent: "start", textTransform: "none", color: "#444444" },
  };
};

export default AccountButton;
