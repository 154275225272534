import { Step, StepLabel, Stepper } from "@mui/material";
import { useThis } from "../../assets/context/Context";
import getConstant from "../../assets/js/Constant";
import { Fragment, useEffect } from "react";

/**
 * @name GenericStepHorizontal
 * @description Método que devuelve un componente de tipo GenericStepHorizontal
 * @param {Object} { steps=Array, views=Array }
 * @returns View
 * @version 1.0
 */
const GenericStepHorizontal = ({ steps, views }) => {
  const { step, setStep } = useThis();
  const constant = getConstant();
  const css = styles(constant.primaryColor);

  /**
   * @name addSteps
   * @description Método que carga los pasos al Stepper
   */
  const addSteps = () =>
    steps.map((label) => (
      <Step key={label} {...{}}>
        <StepLabel {...{}}>{label}</StepLabel>
      </Step>
    ));

  /** Efecto que se ejecuta al iniciar el componente */
  useEffect(() => {
    setStep(0);
  }, []);

  return (
    <div>
      <Stepper activeStep={step} sx={css.stepper}>
        {addSteps()}
      </Stepper>
      <Fragment>
        <div style={css.fragment}>{views[step]}</div>
      </Fragment>
    </div>
  );
};

/**
 * @name styles
 * @description Método encargado de devolver los estilos a los componentes
 * @param {String} primaryColor
 * @returns Object
 */
const styles = (primaryColor) => {
  return {
    stepper: {
      width: "600px",
      position: "relative",
      "& .MuiStepLabel-root .Mui-active": { color: "#000000" },
      "& .MuiStepLabel-root .Mui-completed": { color: primaryColor },
    },
    fragment: { display: "flex", justifyContent: "center", alignItems: "center" },
  };
};

export default GenericStepHorizontal;
