/**
 * @name Label
 * @description Método que devuelve un componente de tipo Label
 * @param {String} text
 * @param {String} fontSize
 * @param {String} margin
 * @param {String} width
 * @param {String} fontWeight
 * @param {String} textAlign
 * @param {String} color
 * @param {String} whiteSpace
 * @returns View
 * @version 1.0
 */
const Label = ({ text, fontSize, margin, width, fontWeight, textAlign, color = "#4b4b4b", whiteSpace }) => {
  return <p style={{ fontSize, color, margin, width, fontWeight, textAlign, whiteSpace }}> {text} </p>;
};

export default Label;
