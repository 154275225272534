import { InputAdornment, TextField, ThemeProvider, createTheme } from "@mui/material";
import getConstant from "../../assets/js/Constant";

/** Inicialización de variables */
const constant = getConstant();

/**
 * @name InputText
 * @description Método que devuelve un componente tipo TextField
 * @param {String} label - Nombre en el placeHolder
 * @param {String} size - Tamaños: small, medium
 * @param {Number} marginTop
 * @param {String} width
 * @param {String} type - Tipos: password, number, email...
 * @param {String} display - fex ó none
 * @param {useState} data
 * @param {useState} setData
 * @param {String} name - Nombre del input
 * @param {String} value
 * @param {useRef} referece
 * @param {Icon} icon
 * @param {number} marginBottom
 * @param {Function} onKey
 * @param {Number} rows - Más de 1 para textArea
 * @param {String} color
 * @param {Boolean} readOnly
 * @returns View
 * @version 1.0
 */
const InputText = ({
  rows,
  size,
  type,
  icon,
  data,
  name,
  label,
  onKey,
  value,
  width,
  setData,
  referece,
  marginTop,
  marginBottom,
  color = "#555",
  display = "flex",
  readOnly = false,
}) => {
  const css = styles(marginTop, width, display, marginBottom);

  /**
   * @name onChange
   * @description Obtiene los datos de los componentes
   * @param {Event} e
   * @version 1.0
   */
  const onChange = (e) => setData({ ...data, [e.target.name]: e.target.value });

  return (
    <ThemeProvider theme={theme}>
      <TextField
        rows={rows}
        type={type}
        name={name}
        size={size}
        value={value}
        label={label}
        sx={css.input}
        onKeyUp={onKey}
        color="classic"
        variant="outlined"
        inputRef={referece}
        onChange={onChange}
        multiline={rows > 1}
        InputProps={{
          readOnly,
          endAdornment: (
            <InputAdornment sx={{ color }} position="end">
              {icon}
            </InputAdornment>
          ),
        }}
      />
    </ThemeProvider>
  );
};

/**
 * @name theme
 * @description Método encargado de crear el estilo para el input
 * @version 1.0
 */
const theme = createTheme({
  palette: {
    classic: {
      main: constant.primaryColor,
    },
  },
});

/**
 * @name styles
 * @description Método encargado de devolver los estilos a los componentes
 * @param {Number} marginTop
 * @param {String} width
 * @param {String} display
 * @param {Number} marginBottom
 * @returns Object
 * @version 1.0
 */
const styles = (marginTop, width, display, marginBottom) => {
  return {
    input: { minWidth: width, mt: marginTop, mb: marginBottom, display },
  };
};

export default InputText;
