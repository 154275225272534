import TouchAppIcon from "@mui/icons-material/TouchApp";
import { useThis } from "../../assets/context/Context";
import EntryTypeCard from "./EntryTypeCard";
import { useEffect } from "react";
import Label from "./Label";

/**
 * @name SelectEntry
 * @description Método encargado de devolver un contenedor para selección de tipos de entrada
 * @returns View
 * @version 1.0
 */
const SelectEntry = () => {
  const css = styles();
  const { lang, setBackItem, setBackForm, setYearText } = useThis();

  useEffect(() => {
    setBackItem(false);
    setBackForm(<></>);
    // Muestra el texto al pié de página
    setYearText("block");
  }, []);

  return (
    <div style={css.container}>
      <div style={css.title}>
        <TouchAppIcon sx={css.icon} />
        <Label text={lang.selectEntryText} margin="0 0 0 10px" />
      </div>
      <div style={css.containerCard}>
        <EntryTypeCard />
        <EntryTypeCard type={1} />
      </div>
      <br />
    </div>
  );
};

/**
 * @name styles
 * @description Método encargado de devolver los estilos a los componentes
 * @returns Object
 */
const styles = () => {
  return {
    icon: { color: "#555555" },
    title: { display: "flex", alignItems: "center", marginBottom: "15px" },
    containerCard: { width: "100%", display: "flex", justifyContent: "space-evenly", flexWrap: "wrap" },
    container: { width: "100%", display: "flex", alignItems: "center", flexDirection: "column", position: "relative" },
  };
};

export default SelectEntry;
