import { useThis } from "../../assets/context/Context";
import getConstant from "../../assets/js/Constant";
import table from "../../images/table.svg";
import { StyledBadge } from "./AvatarUI";
import { Slider } from "@mui/material";

/**
 * @name ZoomTable
 * @description Muestra el modal para cambiar de tamaño a los espacios (mesa, express, llevar)
 * @returns View
 * @version 1.0
 */
const ZoomTable = () => {
  const constant = getConstant();
  const { lang, size, setSize } = useThis();
  const css = styles(constant, size);

  return (
    <div style={css.box}>
      <div style={css.container}>
        <div style={css.containerImage}>
          <StyledBadge overlap="circular" anchorOrigin={css.pointPosition} variant="dot" sx={css.point}>
            <p style={css.title}>{lang.size + " #" + size}</p>
            <img style={css.img} src={table} alt={lang.table} />
          </StyledBadge>
        </div>
      </div>
      <Slider min={80} max={200} value={size} aria-label="Default" valueLabelDisplay="auto" onChange={(e) => setSize(e.target.value)} />
    </div>
  );
};

/**
 * @name styles
 * @description Método encargado de devolver los estilos a los componentes
 * @param {Object} constant
 * @param {String} size
 * @returns Object
 * @version 1.0
 */
const styles = (constant, size) => {
  return {
    box: { display: "flex", flexDirection: "column" },
    containerImage: { padding: "3px", position: "absolute" },
    pointPosition: { vertical: "bottom", horizontal: "right" },
    img: { width: size + "px", height: size + "px", padding: "0px 5px" },
    point: {
      "& .MuiBadge-badge": {
        width: "15px",
        height: "15px",
        borderRadius: "50%",
        color: constant.primaryColor,
        backgroundColor: constant.primaryColor,
      },
    },
    container: {
      width: "300px",
      display: "flex",
      height: "250px",
      alignItems: "center",
      marginBottom: "10px",
      border: "1px solid #CCC",
      justifyContent: "center",
    },
    title: {
      width: "100%",
      fontSize: "14px",
      fontFamily: "Arial",
      textAlign: "center",
      position: "absolute",
      color: "rgb(78, 78, 78)",
      textShadow: "2px 0 #fff, -2px 0 #fff, 0 2px #fff, 0 -2px #fff, 1px 1px #fff, -1px -1px #fff, 1px -1px #fff, -1px 1px #fff",
    },
  };
};

export default ZoomTable;
