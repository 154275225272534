import CircularProgress from "@mui/material/CircularProgress";
import { darkenColor } from "../../assets/js/Commons";
import getConstant from "../../assets/js/Constant";
import { Tooltip, Zoom } from "@mui/material";
import { green } from "@mui/material/colors";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";

/** Inicialización de variables */
const constant = getConstant();

/**
 * @name LoadingButton
 * @description Método que devuelve el componente botón con loading
 * @param {Boolean} loading
 * @param {Function} handleclick
 * @param {String} text
 * @param {Number} marginTop
 * @param {String} width
 * @param {String} type
 * @param {Icon} icon
 * @param {String} height
 * @returns View
 * @version 1.0
 */
const LoadingButton = ({ loading, handleClick, text, marginTop, width, type = "button", icon, color = constant.primaryColor, height }) => {
  const css = styles(!loading, width, marginTop, color, height);
  return (
    <Box sx={css.box}>
      <Tooltip TransitionComponent={Zoom} title={text}>
        <div>
          <Button variant="contained" sx={css.button} disabled={loading} onClick={handleClick} type={type} endIcon={icon}>
            {text}
          </Button>
        </div>
      </Tooltip>
      {loading && <CircularProgress size={24} sx={css.icon} />}
    </Box>
  );
};

/**
 * @name stylebutton
 * @description Método que devuelve los estilos
 * @param {Boolean} loading
 * @param {String} width
 * @param {Number} marginTop
 * @param {String} primaryColor
 * @param {String} height
 * @returns Object
 */
const styles = (loading, width, marginTop, primaryColor, height) => {
  return {
    button: {
      background: loading ? primaryColor : darkenColor(primaryColor, 30),
      "&:hover": { background: darkenColor(primaryColor, 20) },
      minWidth: width,
      height,
    },
    box: { mt: marginTop, position: "relative" },
    icon: { color: green[500], position: "absolute", top: "50%", left: "50%", marginTop: "-12px", marginLeft: "-12px" },
  };
};

export default LoadingButton;
