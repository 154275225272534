import { Avatar, Badge, Stack, styled } from "@mui/material";
import { useThis } from "../../assets/context/Context";
import { imageInSvg } from "../../assets/js/Commons";
import { useEffect, useState } from "react";
import MenuCard from "./MenuCard";

/**
 * @name AvatarUI
 * @description Método encargado de devolver un componente tipo Avatar
 * @param {String} backgroundColor
 * @param {String} colorText
 * @returns View
 * @version 1.0
 */
const AvatarUI = ({ backgroundColor, colorText }) => {
  const { user } = useThis();

  const [content, setContent] = useState("B");
  const [color, setColor] = useState("#5BBE7C");
  const [component, setComponent] = useState(null);
  const css = styles(user, backgroundColor, colorText, color);

  /**
   * @name handleClick
   * @param {Event} e
   * @description Evento para manejar el click del avatar y mostrar el menú
   */
  const handleClick = (e) => setComponent(e.currentTarget);

  useEffect(() => {
    // Establece la letra o imagen en el avatar y el color de estado
    if (!user) return;
    if (!user.emailVerified) setColor("#E88229");
    if (user.email) setContent(user.email.substring(0, 1));
    if (user.photoURL) return setContent(imageInSvg(user.photoURL));
    if (user.displayName) setContent(user.displayName.substring(0, 1));
  }, [user]);

  return (
    <div style={css.container}>
      <Stack sx={{ cursor: "pointer" }} direction="row" spacing={2}>
        <StyledBadge overlap="circular" anchorOrigin={css.anchor} variant="dot" sx={css.badge}>
          <Avatar sx={css.avatar} onClick={handleClick}>
            {content}
          </Avatar>
        </StyledBadge>
      </Stack>
      <MenuCard component={component} setComponent={setComponent} logoutItem={true} />
    </div>
  );
};

/**
 * @name StyledBadge
 * @description Estilo del círculo animado del avatar
 */
const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    "&::after": {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
      animation: "ripple 1.2s infinite ease-in-out",
      border: "1px solid currentColor",
      content: '""',
    },
  },
  "@keyframes ripple": {
    "0%": {
      transform: "scale(.8)",
      opacity: 1,
    },
    "100%": {
      transform: "scale(2.4)",
      opacity: 0,
    },
  },
}));

/**
 * @name styles
 * @description Método encargado de devolver los estilos a los componentes
 * @returns Object
 */
const styles = (user, backgroundColor, colorText, color) => {
  return {
    avatar: { backgroundColor, color: colorText },
    anchor: { vertical: "bottom", horizontal: "right" },
    badge: { "& .MuiBadge-badge": { backgroundColor: color, color } },
    container: { display: user ? "block" : "none", position: "absolute", top: "10px", right: "10px", zIndex: 10 },
  };
};

export default AvatarUI;
export { StyledBadge };
