import { useThis } from "../../assets/context/Context";
import { Warning } from "@mui/icons-material";
import DOMPurify from "dompurify";
import Label from "./Label";

/**
 * @name DeleteAnything
 * @description Método que retorna el contenido para el modal de eliminar genérico
 * @param {Object} { message: String }
 * @returns View
 * @version 1.0
 */
const DeleteAnything = ({ message }) => {
  const { lang } = useThis();
  const css = styles();

  /**
   * @description Elimina el <script> malicioso
   */
  const secureMessage = DOMPurify.sanitize(message);

  return (
    <div style={css.container}>
      <p dangerouslySetInnerHTML={{ __html: secureMessage }} />
      <br />
      <div style={css.containerWarning}>
        <Warning sx={css.warningIcon} />
        <Label fontSize={"12px"} text={lang.deleteGenericWarning} fontWeight={"500"} />
      </div>
    </div>
  );
};

/**
 * @name styles
 * @description Método encargado de devolver los estilos a los componentes
 * @returns Object
 * @version 1.0
 */
const styles = () => {
  return {
    container: { width: "300px" },
    containerWarning: { display: "flex", alignItems: "center" },
    warningIcon: { color: "#D8C249", marginRight: "10px", fontSize: "20px" },
  };
};

export default DeleteAnything;
