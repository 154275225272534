import { showModalOthers } from "../../views/components/modules/Administration";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import FolderSharedIcon from "@mui/icons-material/FolderShared";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import DescriptionIcon from "@mui/icons-material/Description";
import EngineeringIcon from "@mui/icons-material/Engineering";
import TerminalIcon from "@mui/icons-material/Terminal";

/**
 * @name submenuOptions
 * @description Constante que devuelve los items del menú Home
 * @param {Object} lang
 * @returns {Object}
 * @version 1.0
 */
const submenuOptions = (lang) => {
  return {
    CR: [
      { text: lang.users, icon: <EngineeringIcon />, callBack: showModalOthers, color: "#88b999" },
      { text: lang.company, icon: <FolderSharedIcon />, callBack: null, color: "#af88b9" },
      { text: lang.atv, icon: <DescriptionIcon />, callBack: null, color: "#8897b9" },
      { text: lang.system, icon: <TerminalIcon />, callBack: null, color: "#cd8484" },
      { text: lang.print, icon: <LocalPrintshopIcon />, callBack: null, color: "#ec9f83" },
      { text: lang.others, icon: <ContentPasteIcon />, callBack: null, color: "#cdb274" },
    ],
    OT: [
      { text: lang.users, icon: <EngineeringIcon />, callBack: showModalOthers, color: "#88b999" },
      { text: lang.company, icon: <FolderSharedIcon />, callBack: null, color: "#af88b9" },
      { text: lang.system, icon: <TerminalIcon />, callBack: null, color: "#cd8484" },
      { text: lang.print, icon: <LocalPrintshopIcon />, callBack: null, color: "#ec9f83" },
      { text: lang.others, icon: <ContentPasteIcon />, callBack: null, color: "#cdb274" },
    ],
  };
};

export { submenuOptions };
