import { getFirstMissingNumber } from "../../assets/js/Commons";
import { useThis } from "../../assets/context/Context";
import imgExpress from "../../images/express.svg";
import imgCarry from "../../images/carry.svg";
import imgTable from "../../images/table.svg";
import { styled } from "@mui/material";
import Label from "./Label";

/**
 * @name CreateService
 * @description Método que carga los componentes del modal para crear un servicio (mesas, express, llevar)
 * @param {Object} { index, setIndex, services }
 * @returns View
 * @version
 */
const CreateService = ({ index, setIndex, services }) => {
  const css = styles();
  const { lang } = useThis();
  const imgServices = [imgTable, imgExpress, imgCarry];
  const textServices = [lang.table, lang.express, lang.carry];

  return (
    <div style={css.container}>
      <Label text={textServices[index] + " N.° " + getFirstMissingNumber(services[index])} textAlign={"center"} fontSize={"18px"} fontWeight={"500"} />
      <img style={css.imgMain} width={230} src={imgServices[index]} alt="service" />
      <div style={css.ImageSelectorContainer}>
        <ImageSelector className={index === 0 ? "active-img-selector" : ""} src={imgTable} alt="table" onClick={() => setIndex(0)} />
        <ImageSelector className={index === 1 ? "active-img-selector" : ""} src={imgExpress} alt="express" onClick={() => setIndex(1)} />
        <ImageSelector className={index === 2 ? "active-img-selector" : ""} src={imgCarry} alt="carry" onClick={() => setIndex(2)} />
      </div>
    </div>
  );
};

/**
 * @name ImageSelector
 * @description Agrega estilo personalizado a la etiqueta img
 * @version 1.0
 */
const ImageSelector = styled("img")({
  padding: "2px",
  width: "95px",
  opacity: "0.6",
  cursor: "pointer",
  transition: "0.3s",
  borderRadius: "5px",
  border: "1px solid rgb(139, 139, 139)",
  "&:hover": {
    opacity: "1",
    padding: "2px",
    transform: "scale(1.08)",
    boxShadow: "0 2px 2px rgba(1, 23, 46, 0.6)",
  },
});

/**
 * @name styles
 * @description Método encargado de devolver los estilos a los componentes
 * @returns Object
 * @version 1.0
 */
const styles = () => {
  return {
    imgMain: { alignContent: "center" },
    ImageSelectorContainer: { width: "330px", display: "flex", justifyContent: "space-between" },
    container: { width: "330px", display: "flex", flexDirection: "column", alignItems: "center" },
  };
};

export default CreateService;
