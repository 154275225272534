import { Card, CardMedia, IconButton, Tooltip, Zoom } from "@mui/material";
import { validateSubscription } from "../../assets/js/firebaseMethods";
import ShoppingCar from "@mui/icons-material/ShoppingCartCheckout";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Calendar from "@mui/icons-material/CalendarMonth";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useThis } from "../../assets/context/Context";
import LoyaltyIcon from "@mui/icons-material/Loyalty";
import { formatDate } from "../../assets/js/Commons";
import getConstant from "../../assets/js/Constant";
import SendIcon from "@mui/icons-material/Send";
import WorkIcon from "@mui/icons-material/Work";
import defalut from "../../images/default.svg";
import BranchCardMenu from "./BranchCardMenu";
import LoadingButton from "./LoadingButton";
import LoadingBar from "./LoadingBar";
import Home from "../containers/Home";
import { useState } from "react";
import Message from "./Message";

const separator = ": ";

/**
 * @name BranchCard
 * @description Método encargado de devolver un componente de tipo BranchCard
 * @param {Object} { id, name, ide, consecutive, address, activityCode, electronicBill, initialDate, expirationDate, img, administrator }
 * @returns View
 * @version 1.0
 */
const BranchCard = ({ id, name, ide, consecutive, address, activityCode, electronicBill, initialDate, expirationDate, img, administrator }) => {
  const constant = getConstant();
  const css = styles(constant.primaryColor);
  const { lang, setForm, user, setBranch } = useThis();

  const [snack, setSnack] = useState([]); // [Índice 0 = código del mensaje, Índice 1 = tipo de mensaje]
  const [showBar, setShowBar] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  /**
   * @name onClickButton
   * @description Método que ejecuta el evento del botón
   * @param {Event} e
   * @version 1.0
   */
  const onClickButton = async (e) => {
    e.preventDefault();
    setShowBar(true);
    const response = await validateSubscription(user, administrator, id);
    if (response.error) {
      setShowBar(false);
      return setSnack([response.errorCode, constant.error]);
    }
    if (!response.hasValidSubscription) {
      setShowBar(false);
      return setSnack([49, constant.error]);
    }
    setForm(<Home />);
    setShowBar(false);
    setBranch({ branchId: id, administrator });
  };

  /**
   * @name handleMenuClick
   * @description Método que ejecuta el evento del menú
   * @param {Event} e
   * @version 1.0
   */
  const handleMenuClick = (e) => setAnchorEl(e.currentTarget);

  return (
    <Card sx={css.card}>
      <div style={css.section}>
        <header style={css.header}>
          <p style={css.name}>{name}</p>
          <Tooltip TransitionComponent={Zoom} title={lang.options}>
            <IconButton onClick={handleMenuClick} sx={css.iconButton} aria-label="settings">
              <MoreVertIcon sx={css.iconSetting} />
            </IconButton>
          </Tooltip>
        </header>
        <div style={css.id}>
          <p>{ide}</p>
          <p>{lang.branch + separator + consecutive}</p>
        </div>
        <CardMedia sx={css.cardMedia} component="img" height="194" image={img || defalut} alt="logo" />
        <ItemsCardBranch
          css={css}
          lang={lang}
          address={address}
          initialDate={initialDate}
          activityCode={activityCode}
          electronicBill={electronicBill}
          expirationDate={expirationDate}
        />
      </div>
      <LoadingBar visible={showBar} />
      <footer style={css.footer}>
        <LoadingButton text={lang.go} handleClick={onClickButton} width={"330px"} icon={<SendIcon />} />
      </footer>
      <BranchCardMenu anchorEl={anchorEl} setAnchorEl={setAnchorEl} consecutive={consecutive} id={id} administrator={administrator} />
      <Message snack={snack}></Message>
    </Card>
  );
};

/**
 * @name ItemsCardBranch
 * @description Función que devuelve un componente de tipo lista con los ítems del card
 * @param {Object} - { css, lang, address, activityCode, companyType, initialDate, expirationDate }
 * @returns View
 * @version 1.0
 */
const ItemsCardBranch = ({ css, lang, address, activityCode, electronicBill, initialDate, expirationDate }) => {
  return (
    <ul style={css.ul}>
      {electronicBill && (
        <li style={css.li}>
          <WorkIcon sx={css.icons} />
          <p>
            <span style={css.bold}>{lang.activityCode + separator}</span>
            {activityCode}
          </p>
        </li>
      )}
      <li style={css.li}>
        <LoyaltyIcon sx={css.icons} />
        <p>
          <span style={css.bold}>{lang.electronicBill} </span>
          {electronicBill ? lang.yes : lang.no}
        </p>
      </li>
      <li style={css.li}>
        <Calendar sx={css.icons} />
        <p>
          <span style={css.bold}>{lang.created + separator}</span>
          {formatDate(initialDate, lang)}
        </p>
      </li>
      <li style={css.li}>
        <ShoppingCar sx={css.icons} />
        <p>
          <span style={css.bold}>{lang.expiration + separator}</span>
          {formatDate(expirationDate, lang)}
        </p>
      </li>
      <li style={css.li}>
        <LocationOnIcon sx={css.icons} />
        <p>
          <span style={css.bold}>{lang.address + separator}</span>
          {address || lang.noAddress}
        </p>
      </li>
    </ul>
  );
};

/**
 * @name styles
 * @description Método encargado de devolver los estilos a los componentes
 * @returns Object
 * @param {String} primaryColor
 * @version 1.0
 */
const styles = (primaryColor) => {
  return {
    card: {
      width: 350,
      height: 490,
      margin: "10px",
      display: "flex",
      flexDirection: "column",
      boxShadow: "rgba(100, 100, 111, 0.2) 0px 7px 20px 0px",
    },
    li: { display: "flex" },
    iconButton: { width: "auto" },
    iconSetting: { color: primaryColor },
    bold: { fontWeight: "700", color: "#444" },
    cardMedia: { border: "1px solid #F0F0F0" },
    section: { borderBottom: "1px solid #ccc", height: "430px" },
    id: { display: "flex", justifyContent: "space-between", alignItems: "center", padding: "3px 15px" },
    icons: { marginRight: "5px", color: "#666", fontSize: "22px", display: "flex", alignItems: "center" },
    footer: { display: "flex", flex: "1", width: "100%", justifyContent: "center", alignItems: "center" },
    name: { textAlign: "left", color: "#666", fontFamily: "inherit", fontSize: "14px", fontWeight: "bold" },
    header: { display: "flex", justifyContent: "space-between", alignItems: "center", padding: "10px 15px 0px 15px" },
    ul: { display: "flex", flexDirection: "column", justifyContent: "space-evenly", listStyle: "none", paddingLeft: "15px" },
  };
};
export default BranchCard;
