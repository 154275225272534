import { SvgIcon } from "@mui/material";

/**
 * @name IconMicrosoft
 * @description Método que devuelve el ícono de Microsoft
 * @returns {SvgIcon}
 * @version 1.0
 */
const IconMicrosoft = () => {
  return (
    <SvgIcon>
      <svg version="1.2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
        <defs>
          <image
            width="24"
            height="24"
            id="microsoft"
            href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAApgAAAKYB3X3/OAAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAACCSURBVEiJ7ZSxDcJAEATnTxd8CBlNkJK/CFwAETW4QV4EbsEd0ASBg5eOFlZIDizdxrs7J5205Xu/vYAJQVGsPecLwKL4gW5q+Z+abMdyABKQgASAR7GmmsfYVgcGyBm/Pt76OdvgczoDVY54CXm4iOoNKljImeM/OQEJOAig79jff9AUF4fE3EHkAAAAAElFTkSuQmCC"
          />
        </defs>
        <use href="#microsoft" transform="matrix(1,0,0,1,0,0)" />
      </svg>
    </SvgIcon>
  );
};

export default IconMicrosoft;
