import { Step, StepLabel, Stepper } from "@mui/material";
import { useThis } from "../../assets/context/Context";
import getConstant from "../../assets/js/Constant";
import StepRegister1 from "./StepRegister1";
import StepRegister2 from "./StepRegister2";
import StepRegister3 from "./StepRegister3";
import { Fragment, useEffect } from "react";
import Label from "./Label";

/**
 * @name StepperHorizontal
 * @description Método que devuelve un componente de tipo StepperHorizontal
 * @param {Array} steps
 * @returns View
 * @version 1.0
 */
const StepperHorizontal = ({ steps }) => {
  const constant = getConstant();
  const css = styles(constant.primaryColor);
  const { lang, step, setStep, user } = useThis();
  // Array con las vistas de los pasos
  const views = [<StepRegister1 />, <StepRegister2 />, <StepRegister3 />, <StepRegister3 />];

  /**
   * @name addSteps
   * @description Método que carga los pasos al Stepper
   */
  const addSteps = () =>
    steps.map((label) => (
      <Step key={label} {...{}}>
        <StepLabel {...{}}>{label}</StepLabel>
      </Step>
    ));

  useEffect(() => {
    if (user) {
      // Avanza a la pantalla de verificación de correo
      setStep(2);
    } else {
      setStep(0);
    }
  }, []);

  return (
    <div>
      <Label text={lang.registerTitle} fontWeight="500" fontSize="20px" margin="0 0 15px 0" />
      <Stepper activeStep={step} sx={css.stepper}>
        {addSteps()}
      </Stepper>
      <Fragment>
        <div style={css.fragment}>{views[step]}</div>
      </Fragment>
    </div>
  );
};

/**
 * @name styles
 * @description Método encargado de devolver los estilos a los componentes
 * @param {String} primaryColor
 * @returns Object
 */
const styles = (primaryColor) => {
  return {
    stepper: {
      width: "600px",
      position: "relative",
      "& .MuiStepLabel-root .Mui-active": { color: "#000000" },
      "& .MuiStepLabel-root .Mui-completed": { color: primaryColor },
    },
    fragment: { display: "flex", justifyContent: "center", alignItems: "center" },
  };
};

export default StepperHorizontal;
