import { Step, StepContent, StepLabel, Stepper } from "@mui/material";
import { useThis } from "../../assets/context/Context";
import getConstant from "../../assets/js/Constant";
import Box from "@mui/material/Box";
import { useEffect } from "react";

/**
 * @name GenericStepVertical
 * @description Método que devuelve un componente de tipo GenericStepVertical
 * @param {Object} { steps=Array, views=Array }
 * @returns View
 * @version 1.0
 */
const GenericStepVertical = ({ steps, views }) => {
  const constant = getConstant();
  const { step, setStep } = useThis();
  const css = styles(constant.primaryColor);

  /**
   * @name addSteps
   * @description Método que carga los pasos al Stepper
   */
  const addSteps = () =>
    steps.map((step, index) => (
      <Step key={step}>
        <StepLabel>{step}</StepLabel>
        <StepContent>{views[index]}</StepContent>
      </Step>
    ));

  /** Efecto que se ejecuta al iniciar el componente */
  useEffect(() => {
    setStep(0);
  }, []);

  return (
    <Box sx={css.box}>
      <Stepper sx={css.stepper} activeStep={step} orientation="vertical">
        {addSteps()}
      </Stepper>
    </Box>
  );
};

/**
 * @name styles
 * @description Método encargado de devolver los estilos a los componentes
 * @param {String} primaryColor
 * @returns Object
 * @version 1.0
 */
const styles = (primaryColor) => {
  return {
    stepper: {
      margin: "25px",
      position: "relative",
      "& .MuiStepLabel-root .Mui-active": { color: "#000000" },
      "& .MuiStepLabel-root .Mui-completed": { color: primaryColor },
    },
    box: { maxWidth: window.innerWidth, height: 540, display: "flex", flexDirection: "column", justifyContent: "space-between" },
  };
};

export default GenericStepVertical;
