import { ListItemIcon, MenuItem } from "@mui/material";

/**
 * @name Item
 * @description Método que carga los ítems del Componente Menu MUI
 * @param {Object} { css, icon, text, action }
 * @returns View
 * @version 1.0
 */
const Item = ({ css, icon, text, action }) => {
  return (
    <MenuItem onClick={action}>
      <ListItemIcon sx={css}>{icon}</ListItemIcon>
      {text}
    </MenuItem>
  );
};

export default Item;
