import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useThis } from "../../assets/context/Context";
import EastIcon from "@mui/icons-material/East";
import { Tooltip, Zoom } from "@mui/material";

/**
 * @name ArrowButton
 * @description Método que devuelve un componente de tipo ArrowButton
 * @param {Integer} index - 0 = back, 1 = next
 * @param {function} handleClick
 * @returns View
 * @version 1.0
 */
const ArrowButton = ({ index, handleClick }) => {
  const css = styles();
  const { lang } = useThis();
  const text = [lang.back, lang.next];
  const icon = [<KeyboardBackspaceIcon className="clickText" sx={css.icon} />, <EastIcon className="clickText" sx={css.icon} />];

  return (
    <Tooltip TransitionComponent={Zoom} title={text[index]}>
      <div style={css.container} onClick={() => handleClick()}>
        {icon[index]}
      </div>
    </Tooltip>
  );
};

/**
 * @name styles
 * @description Método encargado de devolver los estilos a los componentes
 * @returns Object
 */
const styles = () => {
  return {
    container: {
      width: "35px",
      height: "35px",
      display: "flex",
      cursor: "pointer",
      alignItems: "center",
      justifyContent: "center",
    },
    icon: { color: "#4b4b4b" },
  };
};

export default ArrowButton;
