import Administration from "../components/modules/Administration";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import { getClaims } from "../../assets/js/firebaseMethods";
import ListItemButton from "@mui/material/ListItemButton";
import { items } from "../../assets/config/config-home";
import { useThis } from "../../assets/context/Context";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Products from "../components/modules/Products";
import getConstant from "../../assets/js/Constant";
import logo from "../../images/logo-light-200.svg";
import Tables from "../components/modules/Tables";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { styled } from "@mui/material/styles";
import ListItem from "@mui/material/ListItem";
import AvatarUI from "../components/AvatarUI";
import { Tooltip, Zoom } from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Divider from "@mui/material/Divider";
import Toolbar from "@mui/material/Toolbar";
import { useEffect, useState } from "react";
import Login from "../components/Login";
import List from "@mui/material/List";
import Lobby from "./Lobby";

/** Inicializan constantes */
const drawerWidth = 240;
const defaultView = <img className="selectDisable" src={logo} alt="logo" width={200} style={{ opacity: 0.8 }} />;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== "open" })(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

/**
 * @name Home
 * @description Método que muestra la pantalla de Home que contiene el drawer
 * @returns View
 * @version 1.0
 */
const Home = () => {
  const { lang, setBackForm, user, setForm, branch } = useThis();
  const constant = getConstant();
  const menu = items(lang);

  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState(lang.home);
  const [content, setContent] = useState(defaultView);

  const css = styles(constant.primaryColor, open);

  // Función que deshabilita el clic derecho
  document.oncontextmenu = () => false;

  /**
   * @name handleClickItem
   * @description Método que maneja la acción de los items del menú principal
   * @param {Number} id
   * @param {String} name
   * @version 1.0
   */
  const handleClickItem = (id, name) => {
    setTitle(name);
    switch (id) {
      case 0:
        setContent(<Tables />);
        break;
      case 1:
        setContent(<Products />);
        break;
      case 10:
        setContent(<Administration />);
        break;
      default:
        setContent(defaultView);
        break;
    }
  };

  /**
   * @name getItems
   * @description Método que obtiene los items del menú principal
   * @param {Array} menu
   * @returns {Array}
   * @version 1.0
   */
  const getItems = (menu) =>
    menu.reduce((acc, item) => {
      acc.push(
        <ListItem onClick={() => handleClickItem(item.id, item.name)} key={item.name} disablePadding sx={css.block}>
          <ListItemButton sx={css.itemButton}>
            <ListItemIcon sx={css.itemIcon}>{item.icon}</ListItemIcon>
            <ListItemText primary={item.name} sx={css.itemText} />
          </ListItemButton>
        </ListItem>
      );
      return acc;
    }, []);

  /** Efecto que se dispara cuando se inicializa la vista de Home */
  useEffect(() => {
    // Estable la vista a la cual devolverse al presionar el botón atrás
    setBackForm(<Lobby />);
    const getUser = async () => {
      const response = await getClaims(user);
      console.log(response.claims);
    };
    getUser();
    console.log(branch.branchId, branch.administrator);
  }, []);

  /** Efecto para validar que el usuario haya iniciado sesión */
  useEffect(() => {
    if (!user) return setForm(<Login />);
  }, [user]);

  return (
    <div style={css.main}>
      <AppBar sx={css.appBar} open={open}>
        <Toolbar style={css.toolBar}>
          <div style={css.toolBarContent}>
            <Tooltip TransitionComponent={Zoom} title={lang.menu}>
              <IconButton color="inherit" aria-label="open drawer" onClick={() => setOpen(!open)} edge="start" sx={css.homeIcon}>
                <MenuIcon />
              </IconButton>
            </Tooltip>
            <p style={css.title}>{title.toUpperCase()}</p>
          </div>
          <AvatarUI backgroundColor="#FFFFFF" colorText="#444444" />
        </Toolbar>
      </AppBar>
      <div style={css.flex}>
        <Drawer variant="permanent" open={open}>
          <DrawerHeader sx={css.drawerHeader}>
            <div style={css.menu}>
              <RestaurantIcon sx={css.menuIcon} />
              <p style={css.menuText}>{lang.menu.toUpperCase()}</p>
            </div>
            <Tooltip TransitionComponent={Zoom} title={lang.minimizeMenu}>
              <IconButton onClick={() => setOpen(!open)}>{<ChevronLeftIcon />}</IconButton>
            </Tooltip>
          </DrawerHeader>
          <Divider />
          <List>{getItems(menu.group1)}</List>
          <Divider />
          <List>{getItems(menu.group2)}</List>
        </Drawer>
        <div style={css.container}>{content}</div>
      </div>
    </div>
  );
};

/**
 * @name styles
 * @description Método encargado de devolver los estilos a los componentes
 * @param {String} primaryColor
 * @param {Boolean} open
 * @returns Object
 * @version 1.0
 */
const styles = (primaryColor, open) => {
  return {
    main: { height: "100vh" },
    flex: { display: "flex" },
    block: { display: "block" },
    menuIcon: { color: primaryColor },
    itemText: { opacity: open ? 1 : 0 },
    appBar: { background: primaryColor },
    toolBar: { justifyContent: "space-between" },
    toolBarContent: { display: "flex", alignItems: "center" },
    homeIcon: { marginRight: 5, ...(open && { display: "none" }) },
    drawerHeader: { display: "flex", justifyContent: "space-between" },
    menu: { display: "flex", paddingLeft: "10px", alignItems: "center" },
    title: { fontFamily: "Arial", letterSpacing: "2px", fontSize: "20px" },
    itemIcon: { minWidth: 0, mr: open ? 3 : "auto", justifyContent: "center" },
    itemButton: { minHeight: 48, justifyContent: open ? "initial" : "center", px: 2.5 },
    menuText: { fontSize: "20px", fontWeight: "bold", paddingLeft: "20px", fontFamily: "Arial" },
    container: {
      display: "flex",
      marginTop: "64px",
      background: "#FFF",
      alignItems: "center",
      position: "relative",
      justifyContent: "center",
      width: "calc(100vw - 65px)",
      height: "calc(100vh - 64px)",
    },
  };
};

export default Home;
