import { createTheme, TextField, ThemeProvider, Tooltip, Zoom } from "@mui/material";
import { Warning, Pending, Cancel, CheckCircle } from "@mui/icons-material";
import { useThis } from "../../assets/context/Context";
import getConstant from "../../assets/js/Constant";
import { useState } from "react";
import Label from "./Label";

/** Inicialización de variables */
const constant = getConstant();

/**
 * @name DeleteUser
 * @description Método que muestra el modal para eliminar un usuario
 * @param {Object} { email, iconIndex, setIconIndex }
 * @returns View
 * @version 1.0
 */
const DeleteUser = ({ row, iconIndex, setIconIndex }) => {
  const css = styles();
  const { lang } = useThis();

  const [data, setData] = useState({ email: "" });

  const arrayIcons = [<Pending sx={css.pendingIcon} />, <CheckCircle sx={css.checkIcon} />, <Cancel sx={css.cancelIcon} />];

  /**
   * @name onChange
   * @description Obtiene los datos de los componentes
   * @param {Event} e
   * @version 1.0
   */
  const onChange = (e) => {
    // Cambia el ícono de acuerdo a lo digitado en el TextField
    if (e.target.value === "") {
      setIconIndex(0);
    } else if (e.target.value === row.email) {
      setIconIndex(1);
    } else {
      setIconIndex(2);
    }
    setData({ ...data, [e.target.name]: e.target.value });
  };

  return (
    <div style={css.container}>
      <Label text={lang.deleteUserMessage + row.email} margin={"0 0 15px 0"} />
      <div style={css.containerWarning}>
        <Warning sx={css.warningIcon} />
        <Label fontSize={"12px"} text={lang.deleteGenericWarning} fontWeight={"500"} />
      </div>
      <div style={css.containerInputs}>
        <ThemeProvider theme={theme}>
          <TextField
            size="small"
            name="email"
            sx={css.input}
            color="classic"
            value={data.email}
            variant="outlined"
            onChange={onChange}
            placeholder={lang.email}
          />
        </ThemeProvider>
        <Tooltip TransitionComponent={Zoom} title={iconIndex === 0 && lang.emailUserMessage.replace("[EMAIL]", row.email)}>
          {arrayIcons[iconIndex]}
        </Tooltip>
      </div>
    </div>
  );
};

/**
 * @name theme
 * @description Método encargado de crear el estilo para el input
 * @version 1.0
 */
const theme = createTheme({
  palette: {
    classic: {
      main: constant.deleteColor,
    },
  },
});

/**
 * @name styles
 * @description Método encargado de devolver los estilos a los componentes
 * @returns Object
 * @version 1.0
 */
const styles = () => {
  return {
    container: { width: "300px" },
    branchText: { fontSize: "22px" },
    input: { width: "100%", marginRight: "5px" },
    containerWarning: { display: "flex", alignItems: "center" },
    pendingIcon: { fontSize: "40px", cursor: "pointer", color: "#333" },
    warningIcon: { color: "#D8C249", marginRight: "10px", fontSize: "20px" },
    checkIcon: { fontSize: "40px", cursor: "pointer", color: constant.checkColor },
    cancelIcon: { fontSize: "40px", cursor: "pointer", color: constant.deleteColor },
    containerInputs: { display: "flex", justifyContent: "space-between", alignItems: "center", marginTop: "15px" },
  };
};

export default DeleteUser;
