import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";
import { useThis } from "../../assets/context/Context";
import getConstant from "../../assets/js/Constant";
import { useRef, useState } from "react";
import ArrowButton from "./ArrowButton";
import InputText from "./InputText";
import Message from "./Message";
import Label from "./Label";
import Login from "./Login";
import Logo from "./Logo";

/**
 * @name StepRegister1
 * @description Método que devuelve un componente de tipo StepRegister1
 * @returns View
 * @version 1.0
 */
const StepRegister1 = () => {
  const css = styles();
  const constant = getConstant();
  const { lang, setStep, setForm, data, setData } = useThis();

  const [snack, setSnack] = useState([]); // [Índice 0 = código del mensaje, Índice 1 = tipo de mensaje]

  const userFocus = useRef(null);

  /**
   * @name next
   * @description Método que avanza un paso del Stepper
   * @returns N/A
   */
  const next = () => {
    if (!data.userName) {
      userFocus.current.focus();
      return setSnack([4, constant.error]);
    }
    setStep(1);
  };

  /**
   * @name back
   * @description Método que retrocede en el Stepper
   * @returns N/A
   */
  const back = () => {
    cleanData();
    setForm(<Login />);
  };

  /**
   * @name cleanData
   * @description Método encargado de limpiar los datos de ingreso y registro
   */
  const cleanData = () => {
    setData({ email: "", password: "", userName: "", remember: false });
  };

  return (
    <div style={css.container}>
      <div style={css.fragment}>
        <Logo type={3} size={200} />
        <div style={css.right}>
          <Label text={lang.createUser} fontSize="20px" fontWeight="500" />
          <Label text={lang.createUserText} fontSize="16px" />
          <InputText
            data={data}
            marginTop={3}
            size={"small"}
            type={"email"}
            name="userName"
            width={"300px"}
            setData={setData}
            referece={userFocus}
            value={data.userName}
            label={lang.userName}
            icon={<EmojiPeopleIcon />}
          />
          <Label text={lang.condition} fontSize="12px" margin="5px 0 0 0" />
        </div>
      </div>
      <div style={css.footer}>
        <ArrowButton index={0} handleClick={back} />
        <ArrowButton index={1} handleClick={next} />
      </div>
      <Message snack={snack}></Message>
    </div>
  );
};

/**
 * @name styles
 * @description Método encargado de devolver los estilos a los componentes
 * @returns Object
 */
const styles = () => {
  return {
    right: { margin: "0", width: "380px" },
    footer: { display: "flex", justifyContent: "space-between", marginTop: "10px" },
    fragment: { display: "flex", justifyContent: "space-between", alignItems: "center", paddingTop: "10px", height: "275px" },
    container: { width: "100%", position: "relative", display: "flex", flexDirection: "column", justifyContent: "space-between" },
  };
};

export default StepRegister1;
