import { setSubscription, uploadAnyFile } from "../../assets/js/firebaseMethods";
import MuiBottomNavigationAction from "@mui/material/BottomNavigationAction";
import { formatNumber, getCurrentBranch } from "../../assets/js/Commons";
import LocalParkingIcon from "@mui/icons-material/LocalParking";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import LowPriorityIcon from "@mui/icons-material/LowPriority";
import { BottomNavigation, styled } from "@mui/material";
import { useThis } from "../../assets/context/Context";
import AddCardIcon from "@mui/icons-material/AddCard";
import PendingIcon from "@mui/icons-material/Pending";
import sinpeMovil from "../../images/sinpe-movil.svg";
import getConstant from "../../assets/js/Constant";
import CheckIcon from "@mui/icons-material/Check";
import endpoints from "../../assets/js/Endpoints";
import InfoIcon from "@mui/icons-material/Info";
import InputFileUpload from "./InputFileUpload";
import logoBAC from "../../images/logo-BAC.svg";
import logoBCR from "../../images/logo-BCR.svg";
import logoBNC from "../../images/logo-BNC.svg";
import { deleteObject } from "firebase/storage";
import defalut from "../../images/default.svg";
import LoadingButton from "./LoadingButton";
import { useEffect, useState } from "react";
import ArrowButton from "./ArrowButton";
import Label from "./Label";
import { v4 } from "uuid";

/**
 * @name RenewStep4
 * @description Método que devuelve un componente de tipo RenewStep4
 * @param {Object} { consecutiveBranch, setSnack, administrator, branchId }
 * @returns View
 * @version 1.0
 */
const RenewStep4 = ({ consecutiveBranch, setSnack, administrator, branchId }) => {
  const constant = getConstant();
  const css = styles(constant.infoColor);
  const { setStep, lang, config, dataBranch, dataRenew, setDataRenew, user, snapshotBranches } = useThis();

  const paymentMethod = config.subscription[dataBranch.country.code].paymentMethod;
  const plan = config.subscription[dataBranch.country.code].plan[dataRenew.docTypes.code][dataRenew.term];

  const [index, setIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const [accountNumbers, setAccountNumbers] = useState([]);
  const [navigationActions, setNavigationActions] = useState([]);
  const [BottomNavigationAction] = useState(getStyleNavigationAction());
  const [currency] = useState(config.subscription[dataBranch.country.code].currency);
  const [sinpeNumber] = useState(config.subscription[dataBranch.country.code].sinpeNumber);
  const [amount] = useState(lang.ammount + ": " + currency + formatNumber(plan.amount.toFixed(2)));

  const paymentMethodViews = [
    <SinpeMovilView css={css} lang={lang} constant={constant} sinpeNumber={sinpeNumber} amount={amount} data={dataRenew} setData={setDataRenew} />,
    <TransferView css={css} accountNumbers={accountNumbers} constant={constant} amount={amount} lang={lang} data={dataRenew} setData={setDataRenew} />,
    <CardView css={css} constant={constant} lang={lang} />,
    <PaypalView css={css} constant={constant} lang={lang} />,
  ];

  /**
   * @name back
   * @description Método que retrocede en el Stepper
   * @returns N/A
   * @version 1.0
   */
  const back = () => {
    setStep(2);
  };

  /**
   * @name sendSubscription
   * @description Método encargado de enviar la solicitud de una suscripción
   * @version 1.0
   */
  const sendSubscription = async () => {
    const branch = getCurrentBranch(snapshotBranches, consecutiveBranch);
    if (!branch) return setSnack([38, constant.error]);
    if (!dataRenew.file) return setSnack([43, constant.error]);
    if (branch.data().electronicBill && dataRenew.docTypes.code === "CF") return setSnack([45, constant.error]);
    setLoading(true);
    // Se almacena la imagen en el Storage de Firebase
    const fileName = v4();
    const documentURL = await uploadAnyFile(endpoints.storeSubDoc(administrator, branchId, dataRenew.paymentMethod.code, fileName), dataRenew.file);
    if (documentURL.error) {
      setLoading(false);
      return setSnack([documentURL.errorCode, constant.error]);
    }
    // Se crea el request para realizar la suscripción
    const data = {
      days: plan.days,
      email: user.email,
      price: plan.amount,
      currency: currency,
      branchID: branchId,
      documentName: fileName,
      document: documentURL.url,
      userName: branch.data().name,
      type: dataRenew.docTypes.code,
      name: dataRenew.docTypes.name,
      country: branch.data().country.code,
      paymentMethod: dataRenew.paymentMethod.code,
    };
    const response = await setSubscription(user, data, administrator);
    if (response.error) {
      // Elimina el archivo del Storage de Firebase si no se agrega la suscripción en Firestore
      try {
        if (!documentURL.error) await deleteObject(documentURL.storageRef);
      } catch (error) {
        /** Error al eliminar la imagen */
      }
      //Muestra el mensaje de error
      setSnack([response.errorCode, constant.error]);
    } else {
      cleanSubscription();
      setSnack([39, constant.success]);
    }
    setLoading(false);
  };

  /**
   * @name cleanSubscription
   * @description Método encargado de limpiar los datos de los steps y vuelve al paso uno
   * @version 1.0
   */
  const cleanSubscription = () => {
    setStep(0);
    setDataRenew(constant.renew);
  };

  /** Efecto para cargar los métodos de pago y los números de cuenta */
  useEffect(() => {
    getPaymentMethods();
    getAccountNumbers();
    setDataRenew({ ...dataRenew, paymentMethod: paymentMethod[index] });
  }, []);

  /**
   * @name getPaymentMethods
   * @description Carga los taps para los métodos de pago
   * @version 1.0
   */
  const getPaymentMethods = () => {
    const icons = [<PhoneIphoneIcon />, <LowPriorityIcon />, <AddCardIcon />, <LocalParkingIcon />];
    // Se crean los taps del componente de navegación con los datos del JSONConfig
    const taps = paymentMethod.reduce((acc, element, index) => {
      if (element.show) acc.push(<BottomNavigationAction sx={css.tab} icon={icons[index]} label={lang.paymentMethods[element.code]} key={element.code} />);
      return acc;
    }, []);
    setNavigationActions(taps);
  };

  /**
   * @name getAccountNumbers
   * @description Carga los números de cuentas bancarias
   * @version 1.0
   */
  const getAccountNumbers = () => {
    const logos = { BAC: logoBAC, BCR: logoBCR, BNC: logoBNC };
    const components = config.subscription[dataBranch.country.code].accountNumbers.reduce((acc, element) => {
      acc.push(
        <div style={css.accountItem} key={element.code + element.currency}>
          <div style={css.accountsContainer}>
            <img style={css.logo} src={logos[element.code]} alt={element.code} />
            <div>
              <Label text={element.name + " - " + lang.currencies[element.currency]} fontSize={"14px"} width={"auto"} />
              <Label text={element.iban} fontSize={"14px"} width={"auto"} fontWeight={"500"} />
            </div>
          </div>
        </div>
      );
      return acc;
    }, []);
    setAccountNumbers(components);
  };

  /**
   * @name handleOnChange
   * @description Método para manejar el evento onChange de los taps
   * @param {*} _
   * @param {Number} index
   * @version 1.0
   */
  const handleOnChange = (_, index) => {
    setIndex(index);
    setDataRenew({ ...dataRenew, paymentMethod: paymentMethod[index] });
  };

  return (
    <div style={css.container}>
      <div style={css.fragment}>
        <Label text={lang.selectPaymentMethod} fontSize={"16px"} fontWeight={500} margin={"0 0 5px 0"} />
        <div style={css.flex}>
          <BottomNavigation showLabels sx={css.menu} value={index} onChange={handleOnChange}>
            {navigationActions}
          </BottomNavigation>
          <div style={css.panelRight}>{paymentMethodViews[index]}</div>
        </div>
      </div>
      <div style={css.footer}>
        <ArrowButton index={0} handleClick={back} />
        <LoadingButton loading={loading} text={lang.generateSub} height={"35px"} icon={<CheckIcon />} handleClick={sendSubscription} />
      </div>
    </div>
  );
};

/**
 * @name SinpeMovilView
 * @description Método encargado de mostrar la vista para el tipo de pago sinpe móvil
 * @param {Object} { css, lang, sinpeNumber, amount, constant, data, setData }
 * @returns View
 * @version 1.0
 */
const SinpeMovilView = ({ css, lang, sinpeNumber, amount, constant, data, setData }) => {
  return (
    <div style={css.flex}>
      <div style={css.sinpeContainerText}>
        <p style={css.message}>
          <InfoIcon sx={css.icon} />
          <span style={css.note}>{lang.alert.toUpperCase() + ": "}</span>
          {lang.sinpeText}
        </p>
        <div style={css.sinpeContainerNumber}>
          <img style={css.imgSinpe} src={sinpeMovil} alt="" />
          <Label text={sinpeNumber} fontSize={"26px"} width={"auto"} fontWeight={"500"} />
        </div>
        <Label width={"auto"} fontSize={"18px"} fontWeight={"500"} textAlign={"right"} text={amount} />
      </div>
      <div style={css.containerInputFile}>
        <img src={data.document ? data.document : defalut} alt="" style={css.img} />
        <InputFileUpload name="document" text={lang.attachDoc} accept={constant.onlyImage} color={"#666"} data={data} setData={setData} />
      </div>
    </div>
  );
};

/**
 * @name TransferView
 * @description Método encargado de mostrar la vista para el tipo de pago por transferencia bancaria
 * @param {Object} { css, lang, accountNumbers, amount, constant, data, setData }
 * @returns View
 * @version 1.0
 */
const TransferView = ({ css, lang, accountNumbers, amount, constant, data, setData }) => {
  return (
    <div style={css.flex}>
      <div style={css.sinpeContainerText}>
        <p style={css.message}>
          <InfoIcon sx={css.icon} />
          <span style={css.note}>{lang.alert.toUpperCase() + ": "}</span>
          {lang.sinpeText}
        </p>
        <div style={css.accounts}>{accountNumbers}</div>
        <Label width={"auto"} fontSize={"16px"} fontWeight={"500"} textAlign={"right"} text={amount} />
      </div>
      <div style={css.containerInputFile}>
        <img src={data.document ? data.document : defalut} alt="" style={css.img} />
        <InputFileUpload name="document" text={lang.attachDoc} accept={constant.onlyImage} color={"#666"} data={data} setData={setData} />
      </div>
    </div>
  );
};

/**
 * @name CardView
 * @description Método encargado de mostrar la vista para el tipo de pago con tarjeta
 * @param {Object} { css, constant, lang }
 * @returns View
 * @version 1.0
 */
const CardView = ({ css, constant, lang }) => {
  return <NotAvailable css={css} constant={constant} lang={lang} />;
};

/**
 * @name PaypalView
 * @description Método encargado de mostrar la vista para el tipo de pago con Paypal
 * @param {Object} { css, constant, lang }
 * @returns View
 * @version 1.0
 */
const PaypalView = ({ css, constant, lang }) => {
  return <NotAvailable css={css} constant={constant} lang={lang} />;
};

/**
 * @name NotAvailable
 * @description Método encargado de mostrar la vista para el tipo de pago no disponible
 * @param {Object} { css, constant, lang }
 * @returns View
 * @version 1.0
 */
const NotAvailable = ({ css, constant, lang }) => {
  return (
    <div style={css.notAvailable}>
      <div style={css.accountsContainer}>
        <PendingIcon sx={{ color: constant.deleteColor }} />
        <Label text={lang.notAvailable} fontSize={"14px"} fontWeight={"500"} margin={"0 0 0 10px"} />
      </div>
    </div>
  );
};

/**
 * @name getNavigationAction
 * @description Método encargado de devolver los estilos de los tabs
 * @returns {MuiBottomNavigationAction}
 * @version 1.0
 */
const getStyleNavigationAction = () =>
  styled(MuiBottomNavigationAction)(() => ({
    color: "#444",
    "&.Mui-selected": {
      color: "#499566",
      background: "#F0F0F0",
    },
  }));

/**
 * @name styles
 * @description Método encargado de devolver los estilos a los componentes
 * @param {String} infoColor
 * @returns Object
 * @version 1.0
 */
const styles = (infoColor) => {
  return {
    flex: { display: "flex" },
    note: { fontWeight: "500" },
    message: { display: "inline", fontSize: "14px" },
    accountItem: { marginTop: "5px", marginBottom: "5px" },
    accountsContainer: { display: "flex", alignItems: "center" },
    footer: { display: "flex", justifyContent: "space-between" },
    logo: { width: "25px", height: "25px", marginRight: "10px" },
    containerInputFile: { display: "flex", flexDirection: "column" },
    imgSinpe: { width: "60px", height: "auto", marginRight: "10px" },
    menu: { flexDirection: "column", width: "100px", height: "auto" },
    sinpeContainerNumber: { display: "flex", justifyContent: "center" },
    tab: { padding: "0", minWidth: "100px", maxWidth: "100px", borderRadius: "5px 0 0 5px" },
    accounts: { maxHeight: "145px", overflowY: "auto", display: "flex", flexDirection: "column" },
    notAvailable: { display: "flex", justifyContent: "center", alignItems: "center", width: "100%" },
    fragment: { display: "flex", flexDirection: "column", height: "285px", justifyContent: "center" },
    img: { width: "170px", height: "172px ", border: "1px solid #CCC", marginBottom: "3px", objectFit: "cover" },
    icon: { fontSize: "16px", verticalAlign: "middle", marginRight: "3px", marginBottom: "3px", color: infoColor },
    container: { width: "100%", position: "relative", display: "flex", flexDirection: "column", justifyContent: "space-between" },
    sinpeContainerText: { display: "flex", flexDirection: "column", width: "285px", justifyContent: "space-between", paddingRight: "15px" },
    panelRight: {
      flex: "1",
      width: "300px",
      display: "flex",
      height: "211.5px",
      padding: "10px 20px",
      background: "#F0F0F0",
      borderRadius: "0 5px 5px 0",
      justifyContent: "space-between",
    },
  };
};

export default RenewStep4;
