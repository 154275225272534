import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import getConstant from "../../assets/js/Constant";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";

/** Inicialización de variables */
const constant = getConstant();

/**
 * @name VisuallyHiddenInput
 * @description Método que oculta el input
 * @version 1.0
 */
const VisuallyHiddenInput = styled("input")({
  left: 0,
  width: 1,
  height: 1,
  bottom: 0,
  overflow: "hidden",
  position: "absolute",
  whiteSpace: "nowrap",
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
});

/**
 * @name InputFileUpload
 * @description Método que muestra un componente tipo Input file
 * @param {Object} { text, accept, data, setData, name, color = constant.primaryColor }
 * @returns View
 * @version 1.0
 */
const InputFileUpload = ({ text, accept, data, setData, name, color = constant.primaryColor }) => {
  const css = styles(color);

  /**
   * @name onChange
   * @description Método que captura la selección de imágenes
   * @param {Event} e
   * @version 1.0
   */
  const onChange = (e) => {
    if (!e.target.files[0]) return setData({ ...data, [name]: "", file: null });
    setData({ ...data, [name]: URL.createObjectURL(e.target.files[0]), file: e.target.files[0] });
  };

  return (
    <Button sx={css.button} component="label" variant="contained" endIcon={<CloudUploadIcon />}>
      {text}
      <VisuallyHiddenInput type="file" accept={accept} onChange={onChange} />
    </Button>
  );
};

/**
 * @name styles
 * @description Método encargado de devolver los estilos a los componentes
 * @param {String} color
 * @returns Object
 * @version 1.0
 */
const styles = (color) => {
  return {
    button: {
      background: color,
      "&:hover": {
        backgroundColor: "#333",
      },
    },
  };
};

export default InputFileUpload;
