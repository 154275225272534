import { SnackbarProvider, useSnackbar } from "notistack";
import { useThis } from "../../assets/context/Context";
import { useEffect } from "react";

/**
 * @name Snack
 * @description Muestra la snackBar
 * @param {Array} snack
 * @version 1.0
 */
const Snack = ({ snack }) => {
  const { message } = useThis();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (snack.length) enqueueSnackbar(message[snack[0]], { variant: snack[1] });
  }, [snack]);
};

/**
 * @name Message
 * @description Método que muestra un componente de tipo snackBar
 * @param {Array} snack
 * @returns View
 */
const Message = ({ snack }) => {
  return (
    <SnackbarProvider maxSnack={3}>
      <Snack snack={snack} />
    </SnackbarProvider>
  );
};

export default Message;
