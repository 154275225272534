import LocalGroceryStoreIcon from "@mui/icons-material/LocalGroceryStore";
import TableRestaurantIcon from "@mui/icons-material/TableRestaurant";
import SettingsRoundedIcon from "@mui/icons-material/SettingsRounded";
import AccessibilityIcon from "@mui/icons-material/Accessibility";
import RamenDiningIcon from "@mui/icons-material/RamenDining";
import ConnectedTvIcon from "@mui/icons-material/ConnectedTv";
import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import AssignmentIcon from "@mui/icons-material/Assignment";
import TimelineIcon from "@mui/icons-material/Timeline";
import DownloadIcon from "@mui/icons-material/Download";
import SavingsIcon from "@mui/icons-material/Savings";

/**
 * @name items
 * @description Constante que devuelve los items del menú Home
 * @param {Object} lang
 * @returns {Object}
 * @version 1.0
 */
const items = (lang) => {
  return {
    group1: [
      { id: 0, name: lang.tables, icon: <TableRestaurantIcon /> },
      { id: 1, name: lang.products, icon: <RamenDiningIcon /> },
      { id: 2, name: lang.command, icon: <ConnectedTvIcon /> },
      { id: 3, name: lang.customers, icon: <AccessibilityIcon /> },
      { id: 4, name: lang.vouchers, icon: <ReceiptLongIcon /> },
      { id: 5, name: lang.reports, icon: <AssignmentIcon /> },
      { id: 6, name: lang.statistics, icon: <TimelineIcon /> },
    ],
    group2: [
      { id: 7, name: lang.closeBox, icon: <SavingsIcon /> },
      { id: 8, name: lang.subscriptions, icon: <LocalGroceryStoreIcon /> },
      { id: 9, name: lang.downloads, icon: <DownloadIcon /> },
      { id: 10, name: lang.administration, icon: <SettingsRoundedIcon /> },
    ],
  };
};

export { items };
