/**
 * @name messages_en
 * @description Método que devuelve los mensajes en inglés
 * @returns {Object} Objeto con los mensajes en inglés
 */
const messages_en = () => {
  return {
    lang: "lang-en",
  };
};

export default messages_en;
