import { collection, deleteDoc, getDocs } from "firebase/firestore";
import { deleteAnyFile } from "./firebaseMethods";
import { db } from "../context/firebase-config";
import { SvgIcon } from "@mui/material";
import COSTA_RICA from "./address/CR";
import getConstant from "./Constant";
import endpoints from "./Endpoints";

/** Inicialización de variables */
const constant = getConstant();

/**
 * @name imageInSvg
 * @description Método que convierte una imagen de url a svg
 * @param {String} imageUrl
 * @param {String} size - Default 40px
 * @returns SvgIcon
 */
const imageInSvg = (imageUrl, size = "40px") => {
  return (
    <SvgIcon sx={{ width: size, height: size }}>
      <svg viewBox="0 0 40 40" width={size} height={size}>
        <image href={imageUrl} alt="" width={size} height={size} />
      </svg>
    </SvgIcon>
  );
};

/**
 * @name isMobileDevice
 * @description Método que detecta si se está desde un dispositivo móvil
 * @returns Boolean
 */
const isMobileDevice = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|Windows Phone/i.test(navigator.userAgent) || window.innerWidth <= constant.maxScreenWidth;
};

/**
 * @name getCRProvinces
 * @description Función que devuelve las provincias de Costa Rica
 * @returns Array
 */
const getCRProvinces = () => {
  return COSTA_RICA().map((option) => {
    const firstLetter = option.name[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
      ...option,
    };
  });
};

/**
 * @name getCRCantonsByProvince
 * @description Función que devuelve los cantones de Costa Rica por provincia
 * @param {Object} province
 * @returns Array
 */
const getCRCantonsByProvince = (province) => {
  const prov = getCRProvinces().filter((prov) => prov.id === province.id);
  return prov[0].cantons.map((option) => {
    const firstLetter = option.name[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
      ...option,
    };
  });
};

/**
 * @name getCRDistrictsByProvincAndCanton
 * @description Función que devuelve los distritos de Costa Rica por provincia y cantón
 * @param {Object} province
 * @param {Object} canton
 * @returns Array
 */
const getCRDistrictsByProvincAndCanton = (province, canton) => {
  const prov = getCRProvinces().filter((prov) => prov.id === province.id);
  const can = prov[0].cantons.filter((can) => can.id === canton.id);
  return can[0].district.map((option) => {
    const firstLetter = option.name[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? "0-9" : firstLetter,
      ...option,
    };
  });
};

/**
 * @name getInfoByIde
 * @description Función encargada de solicitar los datos tributarios ante Hacienda, Costa Rica
 * @param {String} identification
 * @param {Boolean} isElectronicBill
 */
const getInfoByIde = async (identification, isElectronicBill) => {
  const message = { error: false };
  try {
    // Se realiza la petición a Hacienda CR
    const options = { timeout: 1000 };
    let response = await fetch(constant.getInfoByIdePath + identification, options);
    // Se verifica una respuesta válida
    if (!response.ok) return { ...message, error: true, errorCode: 19 };
    response = await response.json();
    // Si seleccionó factura electrónica debe de estar inscrito en Hacienda CR, de lo contrario se devuelve un error
    if (isElectronicBill && (response.situacion.estado === constant.notRegistered || response.situacion.estado === constant.unsubscribed))
      return { ...message, error: true, errorCode: 20 };
    // Se devuelva la respuesta válida
    return { ...message, response };
  } catch (error) {
    console.log(error);
    return { ...message, error: true, errorCode: 21 };
  }
};

/**
 * @name formatDate
 * @description Método encargado de formatear un timestamp
 * @param {Number} timestamp
 * @param {Object} lang
 * @returns {String} - 01/04/2024 a las 15:51
 */
const formatDate = (timestamp, lang) => {
  if (!timestamp) return "";
  // Convertir el timestamp a un objeto de fecha
  const date = timestamp.toDate();

  // Obtener los componentes de la fecha y la hora
  const day = date.getDate().toString().padStart(2, "0");
  const month = (date.getMonth() + 1).toString().padStart(2, "0");
  const year = date.getFullYear();
  const hour = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  const seconds = date.getSeconds().toString().padStart(2, "0");

  // Formatear la fecha y la hora en el formato deseado
  const dateFormated = `${day}/${month}/${year}`;
  const hourFormated = `${hour}:${minutes}:${seconds}`;

  // Retornar la fecha y hora formateadas
  return `${dateFormated}${lang.at}${hourFormated}`;
};

/**
 * @name darkenColor
 * @description Método que oscurece un color hexadecimal
 * @param {String} hex
 * @param {Number} percent
 * @returns {String} Color hexadecimal
 * @version 1.0
 */
const darkenColor = (hex, percent) => {
  let r = parseInt(hex.substring(1, 3), 16);
  let g = parseInt(hex.substring(3, 5), 16);
  let b = parseInt(hex.substring(5, 7), 16);

  r = Math.floor(r * (1 - percent / 100));
  g = Math.floor(g * (1 - percent / 100));
  b = Math.floor(b * (1 - percent / 100));

  const darkerHex = "#" + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
  return darkerHex;
};

/**
 * @name deleteCollections
 * @description Método que elimina los documentos de una colección de Firestore
 * @param {String} endpoint
 * @param {String} administrator
 * @param {String} branchId
 * @version 1.0
 */
const deleteCollections = async (endpoint, administrator, branchId) => {
  try {
    const array = endpoint.split("/");
    const coleccions = await getDocs(collection(db, endpoint));
    console.log("Eliminando la colección:", array[array.length - 1]);
    for (const doc of coleccions.docs) {
      await deleteDoc(doc.ref);
      // En el caso de ser suscripciones se eliminan también los documentos (imágenes)
      if (array[array.length - 1] === constant.subscriptions && doc.data().document) {
        await deleteAnyFile(endpoints.storeSubDoc(administrator, branchId, doc.data().paymentMethod, doc.data().documentName));
      }
      console.log("Documento eliminado correctamente:", doc.id);
    }
    return { error: false };
  } catch (error) {
    return { error: true };
  }
};

/**
 * @name timestampToDaysAndHours
 * @description Método que devuelve la diferencia en días y horas entre 2 Timestamps
 * @param {Timestamp} newTimestamp
 * @param {Timestamp} oldTimestamp
 * @returns {Object} { days, hoursLeft }
 * @version 1.0
 */
const timestampToDaysAndHours = (newTimestamp, oldTimestamp) => {
  const difference = Math.abs(newTimestamp - oldTimestamp);
  const seconds = Math.floor(difference / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  // Calcula las horas restantes
  const hoursLeft = hours % 24;
  return { days, hoursLeft };
};

/**
 * @name getDaysAndHoursText
 * @description Método que devuelve los días y horas en texto
 * @param {Timestamp} expirationDate
 * @param {Object} lang
 * @returns String
 */
const getDaysAndHoursText = (expirationDate, lang) => {
  const daysAndHours = timestampToDaysAndHours(new Date().getTime(), expirationDate.toDate().getTime());
  const daysAnd = daysAndHours.days + (daysAndHours.days === 1 ? lang.dayAnd : lang.daysAnd);
  const hours = daysAndHours.hoursLeft + (daysAndHours.hoursLeft === 1 ? lang.hour : lang.hours);
  return daysAnd + hours;
};

/**
 * @name getCurrentBranch
 * @description Método encargado de obtener una sucursal por consecutivo
 * @param {Number} snapshotBranches - Snapshot con todas las sucursales de Firestore
 * @param {String} consecutiveBranch - Llega al sistema como un String de 3 caracteres
 * @returns  {Boolean, Object}
 * @version 1.0
 */
const getCurrentBranch = (snapshotBranches, consecutiveBranch) => {
  if (snapshotBranches.length) {
    const branch = snapshotBranches.find((doc) => doc.data().consecutive.toString().padStart(3, "0") === consecutiveBranch);
    return branch;
  } else {
    return false;
  }
};

/**
 * @name termText
 * @description Método encargado de devolver el plazo en texto, ejemplo: 9 meses
 * @param {Object} lang
 * @param {Number} term
 * @returns {String}
 * @version 1.0
 */
const termText = (lang, term) => {
  let termText = "";
  if (term < 30) {
    termText = term === 1 ? 1 + " " + lang.day : term + " " + lang.days;
  } else if (term < 360) {
    const auxTerm = term / 30;
    termText = auxTerm === 1 ? 1 + " " + lang.month : auxTerm + " " + lang.months;
  } else {
    const auxTerm = term / 360;
    termText = auxTerm === 1 ? 1 + " " + lang.year : auxTerm + " " + lang.years;
  }
  return termText;
};

/**
 * @name formatNumber
 * @description Método que formatea un monto a millares con coma ejemplo: 9,000.00
 * @param {Number} amount
 * @returns {String}
 * @version 1.0
 */
const formatNumber = (amount) => amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

/**
 * @name getFirstMissingNumber
 * @description Método que obtiene el primer número faltante de una lista de menor a mayor
 * @param {Array} list
 * @returns {Number}
 * @version 1.0
 */
const getFirstMissingNumber = (list) => {
  // Si la lista está vacía, devolvemos 1
  if (list.length === 0) return 1;
  // Si el único número es menor que 1, devolvemos 1, ya que 0 no cuenta
  if (list.length === 1) {
    if (list[0].number < 1) {
      return 1;
    } else {
      // Si el número es 1 o más, devolvemos el siguiente faltante
      return list[0].number + 1;
    }
  }
  // Recorremos la lista buscando el primer número faltante mayor que 0
  for (let i = 0; i < list.length - 1; i++) {
    // Si encontramos un hueco en la secuencia mayor o igual a 1, lo devolvemos
    if (list[i].number >= 1 && list[i].number + 1 !== list[i + 1].number) {
      return list[i].number + 1;
    }
  }
  // Si no falta ningún número, devolvemos el siguiente después del último
  return list[list.length - 1].number + 1;
};

export {
  termText, // Método encargado de devolver el plazo en texto, ejemplo: 9 meses
  imageInSvg, // Método que convierte una imagen de url a svg
  formatDate, // Método encargado de formatear un timestamp => 01/04/2024 a las 15:51
  darkenColor, // Método que oscurece un color hexadecimal
  getInfoByIde, // Función encargada de solicitar los datos tributarios ante Hacienda, Costa Rica
  formatNumber, // Método que formatea un monto a millares con coma ejemplo: 9,000.00
  getCRProvinces, // Función que devuelve las provincias de Costa Rica
  isMobileDevice, // Método que detecta si se está desde un dispositivo móvil
  getCurrentBranch, // Método encargado de obtener una sucursal por consecutivo
  deleteCollections, // Método que eliminar los documentos de una colección de Firestore
  getDaysAndHoursText, // Método que devuelve los días y horas en texto
  getFirstMissingNumber, // Método que obtiene el primer número faltante de una lista de menor a mayor
  getCRCantonsByProvince, // Función que devuelve los cantones de Costa Rica por provincia
  timestampToDaysAndHours, // Método que devuelve la diferencia en días y horas entre 2 Timestamps
  getCRDistrictsByProvincAndCanton, // Función que devuelve los distritos de Costa Rica por provincia y cantón
};
