import { useThis } from "../../assets/context/Context";
import getConstant from "../../assets/js/Constant";
import express from "../../images/express.svg";
import { Tooltip, Zoom } from "@mui/material";
import { useDrag } from "@use-gesture/react";
import table from "../../images/table.svg";
import carry from "../../images/carry.svg";
import { StyledBadge } from "./AvatarUI";
import { useState } from "react";

/** Inicialización de variables */
const constant = getConstant();

/**
 * @name ServiceItem
 * @description Método que se encarga de pintar un sersvicio (mesa, express, llevar) en el módulo Mesas
 * @param {Object} { title, position, status, edge, type, id, setOpenAlertMessage, setSpaceToDelete }
 * @returns View
 * @version 1.0
 */
const ServiceItem = ({ title, position, status, edge, type, id, setOpenAlertMessage, setSpaceToDelete }) => {
  const images = [table, express, carry];
  const { branch, size, setIsDragging, setIsInside, refDeleteTable } = useThis();

  const [XY, setXY] = useState(position);
  const [border, setBorder] = useState(edge);
  const [cursor, setCursor] = useState("grab");

  const css = styles(XY, status, border, cursor, size);

  /**
   * @name currentPosition
   * @description Método que maneja los gestos de cada servicio de la vista
   * @version 1.0
   */
  const currentPosition = useDrag(
    (params) => {
      setXY({ x: params.offset[0], y: params.offset[1] });
      if (params.dragging) {
        setIsDragging(true);
        setCursor("grabbing");
        setIsInside(isInside(params.offset[0], params.offset[1]));
      } else {
        setIsDragging(false);
      }
      if (!params.down) {
        savePosition();
        setBorder(false);
        setCursor("grab");
        if (isInside(params.offset[0], params.offset[1])) {
          setOpenAlertMessage(true);
          setSpaceToDelete({ id, title });
        }
      }
    },
    { pointer: { buttons: -1, touch: true }, from: () => [XY.x, XY.y] }
  );

  /**
   * @name isInside
   * @description Método que valida si un espacio se encuentra dentro del área de eliminar
   * @param {Number} x
   * @param {Number} y
   * @returns {Boolean}
   * @version 1.0
   */
  const isInside = (x, y) =>
    refDeleteTable.current &&
    x + refDeleteTable.current.offsetWidth / 3 > refDeleteTable.current.offsetLeft &&
    x < refDeleteTable.current.offsetLeft + refDeleteTable.current.offsetWidth &&
    y + refDeleteTable.current.offsetHeight / 3 > refDeleteTable.current.offsetTop &&
    y < refDeleteTable.current.offsetTop + refDeleteTable.current.offsetHeight;

  /**
   * @name savePosition
   * @description Método que almacena las coordenadas del servicio en el localstorage
   * @version 1.0
   */
  const savePosition = () => {
    const list = JSON.parse(localStorage.getItem(getStorageKey(branch, "S"))) || {};
    list[id] = { position: XY, edge: false };
    localStorage.setItem(getStorageKey(branch, "S"), JSON.stringify(list));
  };

  return (
    <div {...currentPosition()} style={css.container}>
      <Tooltip TransitionComponent={Zoom} title={title}>
        <StyledBadge overlap="circular" anchorOrigin={css.pointPosition} variant="dot" sx={css.point}>
          <p style={css.title}>{title}</p>
          <img style={css.img} src={images[type]} alt={title} />
        </StyledBadge>
      </Tooltip>
    </div>
  );
};

/**
 * @name getStorageKey
 * @description Método que obtiene un key para el localstorage
 * @param {Object} branch
 * @param {String} code
 * @returns {String}
 * @version 1.0
 */
const getStorageKey = (branch, code) => branch.administrator + branch.branchId + code;

/**
 * @name styles
 * @description Método encargado de devolver los estilos a los componentes
 * @param {Object} position { x: 15, y: 15}
 * @param {Boolean} status true = espacio libre, false = espacio ocupado
 * @param {Boolean} edge true = con borde, false = sin borde
 * @param {String} cursor
 * @param {String} size
 * @returns Object
 * @version 1.0
 */
const styles = (position, status, edge, cursor, size) => {
  return {
    pointPosition: { vertical: "bottom", horizontal: "right" },
    img: { width: size + "px", height: size + "px", padding: "0px 5px" },
    container: {
      cursor,
      padding: "3px",
      top: position.y,
      left: position.x,
      touchAction: "none",
      position: "absolute",
      border: edge ? "2px dotted brown" : "none",
    },
    point: {
      "& .MuiBadge-badge": {
        width: "15px",
        height: "15px",
        borderRadius: "50%",
        color: status ? constant.primaryColor : constant.deleteColor,
        backgroundColor: status ? constant.primaryColor : constant.deleteColor,
      },
    },
    title: {
      width: "100%",
      fontSize: "14px",
      fontFamily: "Arial",
      textAlign: "center",
      position: "absolute",
      color: "rgb(78, 78, 78)",
      textShadow: "2px 0 #fff, -2px 0 #fff, 0 2px #fff, 0 -2px #fff, 1px 1px #fff, -1px -1px #fff, 1px -1px #fff, -1px 1px #fff",
    },
  };
};

export default ServiceItem;
export { getStorageKey };
