/**
 * @name serviceColors
 * @description Método que devuelve los colores para los servicios (mesa, express, llevar)
 * @returns {Array}
 * @version 1.0
 */
const getServiceColors = () => {
  return [
    "D7DE6E",
    "e9d9de",
    "F4AD96",
    "F4CB96",
    "D0F496",
    "A7F496",
    "96F4B8",
    "96C8F4",
    "B096F4",
    "E496F4",
    "F496C2",
    "CCCCCC",
    "FFFFFF",
    "F4C996",
    "E1F496",
  ];
};

export { getServiceColors };
