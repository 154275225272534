import { Card, CardActionArea, CardContent, CardMedia, Typography } from "@mui/material";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import FolderSharedIcon from "@mui/icons-material/FolderShared";
import { useThis } from "../../assets/context/Context";
import getConstant from "../../assets/js/Constant";
import administrator from "../../images/v2.svg";
import collaborator from "../../images/v6.svg";
import Lobby from "../containers/Lobby";

/**
 * @name EntryTypeCard
 * @description Método que devuelve un componente de tipo EntryTypeCard
 * @param {Number} type - 0 = Administrador, 1 = Colaborador
 * @returns View
 * @version 1.0
 */
const EntryTypeCard = ({ type = 0 }) => {
  const constant = getConstant();
  const css = styles(constant.primaryColor);
  const { lang, setForm, setEntryType } = useThis();

  const images = [administrator, collaborator];
  const types = [lang.administrator, lang.collaborator];
  const text = [lang.administratorText, lang.collaboratorText];
  const icons = [<AdminPanelSettingsIcon sx={css.icon} />, <FolderSharedIcon sx={css.icon} />];

  /**
   * @name handleClick
   * @description Método encargado de manejar el evento de click, para cargar el Lobby
   */
  const handleClick = () => {
    setEntryType(type);
    setForm(<Lobby />);
  };

  return (
    <Card sx={css.card}>
      <CardActionArea onClick={handleClick}>
        <CardMedia component="img" height="140" image={images[type]} alt="" />
        <CardContent sx={css.cardContent}>
          <Typography gutterBottom variant="h5" component="div">
            <div style={css.title}>
              {icons[type]}
              {types[type]}
            </div>
          </Typography>
          <Typography variant="body2" sx={css.title}>
            {text[type]}
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

/**
 * @name styles
 * @description Método encargado de devolver los estilos a los componentes
 * @param {String} primaryColor
 * @returns Object
 */
const styles = (primaryColor) => {
  return {
    icon: { color: primaryColor, marginRight: "10px" },
    cardContent: { height: "130px", background: "#444444" },
    card: { maxWidth: 345, height: "270px", marginTop: "15px" },
    title: { display: "flex", alignItems: "center", color: "#FFFFFF" },
  };
};

export default EntryTypeCard;
