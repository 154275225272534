/**
 * @name lang_en
 * @description Método que devuelve los textos en inglés
 * @returns {Object} Objeto con los textos en inglés
 */
const lang_en = () => {
  return {
    lang: "lang-en",
  };
};

export default lang_en;
