import { Autocomplete, TextField, styled } from "@mui/material";
import ClearIcon from "@mui/icons-material/Close";

/**
 * @name AutoCompleteInput
 * @description Método que devuelve un componente de tipo AutoCompleteInput, para selección de opciones
 * @param {Object} { data, collection, text, mb, name, onChange }
 * @returns View
 * @version 1.0
 */
const AutoCompleteInput = ({ data, collection, text, mb, name, onChange }) => {
  const css = styles(mb);

  /**
   * @name onChangeInput
   * @description Método que captura el elemento al ser seleccionado
   * @param {Object} option
   */
  const onChangeInput = (option) => {
    if (option) onChange(option, name);
  };

  return (
    <Autocomplete
      size="small"
      sx={css.input}
      value={data[name]}
      groupBy={(option) => option.firstLetter}
      getOptionLabel={(option) => option.name}
      clearIcon={<ClearIcon fontSize="small" />}
      onChange={(_, newValue) => onChangeInput(newValue)}
      isOptionEqualToValue={(option, value) => option.name === value.name}
      renderInput={(params) => <CustomizedTextField {...params} label={text} />}
      options={collection.sort((a, b) => -b.firstLetter.localeCompare(a.firstLetter))}
    />
  );
};

/**
 * @description Agrega estilo a los inputs de autocompletado
 */
const CustomizedTextField = styled(TextField)`
  .MuiAutocomplete-endAdornment {
    display: flex;
    justify-content: flex-end;
  }
`;

/**
 * @name styles
 * @description Método encargado de devolver los estilos a los componentes
 * @param {String} marginTop
 * @returns Object
 */
const styles = (marginTop) => {
  return { input: { marginTop } };
};

export default AutoCompleteInput;
