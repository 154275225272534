import credentialsLogo from "../../images/logo-credentials.svg";
import lightLogo from "../../images/logo-light-200.svg";
import darkLogo from "../../images/logo-dark-200.svg";
import nameLogo from "../../images/logo-name.svg";
import mailLogo from "../../images/logo-mail.svg";

/**
 * @name Logo
 * @description Método que retorna el logo de la aplicación
 * @param {Number} type 1 = logo claro, 2 = logo oscuro, 3 = Chef con lápiz, 4 = Chef con llave, 5 = Chef con correo
 * @param {Number} size
 * @returns View
 * @version 1.0
 */
const Logo = ({ type, size }) => {
  const logos = [lightLogo, darkLogo, nameLogo, credentialsLogo, mailLogo];
  return <img className="selectDisable" src={logos[type - 1]} alt="logo" width={size} />;
};

export default Logo;
