import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide } from "@mui/material";
import { useThis } from "../../assets/context/Context";
import { forwardRef } from "react";

/**
 * @name AlertMessage
 * @description Método que muestra un alert con uno o dos botones
 * @param {Object} { open, setOpen, icon, message, title, twoButtons, onClick, textButton }
 * @returns View
 * @version 1.0
 */
const AlertMessage = ({ open, setOpen, icon = null, message = "", title = "", twoButtons = false, onClick = () => {}, textButton = "" }) => {
  const css = styles();
  const { lang } = useThis();

  return (
    <Dialog open={open} TransitionComponent={Transition} keepMounted onClose={() => setOpen(false)} sx={css.dialog}>
      <DialogTitle sx={css.title}>
        {icon}&nbsp;{title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-slide-description">{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        {twoButtons && (
          <Button sx={css.black} onClick={() => setOpen(false)}>
            {lang.cancel}
          </Button>
        )}
        <Button sx={css.black} onClick={onClick}>
          {textButton}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

/**
 * @description Maneja el efecto cuando aparece el mensaje
 * @version 1.0
 */
const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

/**
 * @name styles
 * @description Método encargado de devolver los estilos a los componentes
 * @returns Object
 * @version 1.0
 */
const styles = () => {
  return {
    black: { color: "#000" },
    dialog: { zIndex: 9999 },
    title: { display: "flex", alignItems: "center" },
  };
};

export default AlertMessage;
