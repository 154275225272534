import MuiBottomNavigationAction from "@mui/material/BottomNavigationAction";
import EmojiFoodBeverageIcon from "@mui/icons-material/EmojiFoodBeverage";
import RestaurantMenuIcon from "@mui/icons-material/RestaurantMenu";
import FolderSpecialIcon from "@mui/icons-material/FolderSpecial";
import { useThis } from "../../../assets/context/Context";
import { BottomNavigation, styled } from "@mui/material";
import ProductsAndServices from "./ProductsAndServices";
import ProductsCategories from "./ProductsCategories";
import MopedIcon from "@mui/icons-material/Moped";
import ProductsExpress from "./ProductsExpress";
import ProductsMenu from "./ProductsMenu";
import { useState } from "react";

/**
 * @name Products
 * @description Método encargado de mostrar la vista para Productos
 * @returns View
 * @version 1.0
 */
const Products = () => {
  const css = styles();
  const { lang } = useThis();

  const views = [<ProductsCategories />, <ProductsAndServices />, <ProductsMenu />, <ProductsExpress />];

  const [value, setValue] = useState(0);
  const [BottomNavigationAction] = useState(getNavigationAction());

  return (
    <div style={css.box}>
      <div style={css.container}>{views[value]}</div>
      <BottomNavigation showLabels value={value} sx={css.paper} onChange={(_, newValue) => setValue(newValue)}>
        <BottomNavigationAction label={lang.categories} icon={<FolderSpecialIcon />} />
        <BottomNavigationAction label={lang.prodAndServ} icon={<EmojiFoodBeverageIcon />} />
        <BottomNavigationAction label={lang.menu} icon={<RestaurantMenuIcon />} />
        <BottomNavigationAction label={lang.express} icon={<MopedIcon />} />
      </BottomNavigation>
    </div>
  );
};

/**
 * @name getNavigationAction
 * @description Método que devuelve un estilo personalizado para los Bottom Navigation
 * @returns {styled}
 * @version 1.0
 */
const getNavigationAction = () => styled(MuiBottomNavigationAction)(() => ({ color: "#525252", "&.Mui-selected": { color: "#3D935E" } }));

/**
 * @name styles
 * @description Método encargado de devolver los estilos a los componentes
 * @returns Object
 * @version 1.0
 */
const styles = () => {
  return {
    container: { flex: 1 },
    paper: { height: "60px", background: "#eeeeee", width: "100%" },
    box: { display: "flex", flexDirection: "column", width: "100%", height: "100%" },
  };
};

export default Products;
