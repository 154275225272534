import { useThis } from "../../assets/context/Context";
import "../../css/initMessage.css";

/**
 * @name InitMessage
 * @description Método encargado de mostrar el texto animado
 * @param {Object} { index: 0 = "Primero crea una sucursal...", 1 = "Aún no estás vinculado...", 2 = "Error al cargar permisos..." }
 * @returns View
 * @version 1.0
 */
const InitMessage = ({ index }) => {
  const { lang } = useThis();
  return (
    <div className="initMessage">
      <h3>{index === 2 || index === 3 ? lang.sorry : lang.hi}</h3>
      <p className="initMessage-animation">{lang.initMessage[index]}</p>
    </div>
  );
};

export default InitMessage;
