import { Provider } from "./assets/context/Context";
import ReactDOM from "react-dom/client";
import React from "react";
import App from "./App";

const root = ReactDOM.createRoot(document.getElementById("root"));

// Remover "</StrictMode>" en producción y agreagarlos en desarrollo, provoca doble redenderizado
root.render(
  <Provider>
    <App />
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//import reportWebVitals from "./reportWebVitals";
//reportWebVitals();
